import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem,
  Snackbar, Alert, Divider, IconButton, Box, Stack, CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import utils from "../../utils";

const OpNews = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [newsList, setNewsList] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get(`/api/newsclienti`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setNewsList(res.data.news);
      } else {
        setError(error);
      }
    } catch (error) {
      setError("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title || !content || !category) {
      setError('Tutti i campi sono obbligatori.');
      return;
    }
    setIsLoading(true);
    try {
      const res = await axios.post("/api/createnewsclienti", { title, content, category }, {
        headers: {
          ...utils.getAuthHeaders(),
        },
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      const res = await axios.post("/api/deletenewsclienti", { id }, {
        headers: {
          ...utils.getAuthHeaders(),
        },
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Container maxWidth="xl" style={{ padding: '10px' }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
        sx={{ margin: '20px', color: '#000000' }}
      >
        Inserisci Notizia
      </Typography>

      <Paper elevation={3} sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Titolo"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Inserisci il titolo della notizia"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Categoria</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Categoria"
                  required
                >
                  <MenuItem value="Aggiornamenti">Aggiornamenti Software</MenuItem>
                  <MenuItem value="Notizie">Notizie</MenuItem>
                  <MenuItem value="Circolari">Circolari</MenuItem>
                  <MenuItem value="Eventi">Eventi</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contenuto"
                variant="outlined"
                multiline
                rows={6}
                fullWidth
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Inserisci il contenuto della notizia"
                required
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button type="submit" variant="contained" color="primary">
                Inserisci
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
          <Alert onClose={() => setSuccess(false)} severity="success">
            Notizia inserita con successo!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Paper>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" component="h2" gutterBottom align="center">
        Storico delle Notizie
      </Typography>
      {loading ? <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack> : <Paper elevation={3} sx={{ padding: 2 }}>
        {newsList.length === 0 ? (
          <Typography align="center">Nessuna notizia inserita.</Typography>
        ) : (
          newsList.map((news) => (
            <Paper key={news.id} sx={{ padding: 2, marginBottom: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {news.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Categoria: {news.category} | Data: {new Date(news.date).toLocaleDateString()}
                  </Typography>
                </Box>
                <IconButton onClick={() => handleDelete(news.id)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
              <Typography variant="body1" sx={{ marginTop: 1, whiteSpace: 'pre-line' }}>
                {news.content}
              </Typography>
            </Paper>
          ))
        )}
      </Paper>}
    </Container>
  );
};

export default OpNews;