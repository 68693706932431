import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Stack,
  Container,
  CircularProgress,
  ButtonBase,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";

const OpEntiEdilConnect = () => {
  const [companies, setCompanies] = useState([]);
  const [companyTotal, setCompanyTotal] = useState(0); // Totale delle aziende
  const [companyPage, setCompanyPage] = useState(0); // Pagina corrente
  const [companyLoading, setCompanyLoading] = useState(false); // Stato di caricamento
  const [statusMessageCompanies, setStatusMessageCompanies] = useState(""); // Messaggio di stato

  const rowsPerPage = 8;

  useEffect(() => {
    loadCompanies(0);
  }, []);

  const loadCompanies = async (p) => {
    try {
      const res = await axios.get(`/api/edilarchivio?page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setCompanies(res.data.tickets);
        setCompanyTotal(res.data.total);
      } else {
        setStatusMessageCompanies(error);
      }
    } catch (error) {
      setStatusMessageCompanies(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setCompanyLoading(false);
  };

  const handleCompanyPage = (forward) => {
    if (companyPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (companyPage + 1) >= companyTotal && forward) {
      return;
    }
    const newPage = companyPage + (forward ? 1 : -1);
    setCompanyPage(newPage);
    setCompanyLoading(true);
    loadCompanies(newPage);
  };

  const CellStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    userSelect: "auto",
  };

  const centerText = {
    textAlign: "center",
  };

  const dataRowSX = {
    display: "table-row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  };

  const renderTableRow = (row, index) => {
    return (
      <ButtonBase
        component={Link}
        to={`/op/enti/edilconnect/detail?id=${row.id}`}
        key={row.id}
        sx={dataRowSX}
      >
        <TableCell style={{ ...centerText }}>{row.id}</TableCell>
        <TableCell style={{ ...centerText }}>{utils.revDate(row.dataRichiesta)}</TableCell>
        <TableCell style={{ ...centerText }}>{row.nome}</TableCell>
        <TableCell style={{ ...centerText }}>{utils.revDate(row.dataCreazione) || ''}</TableCell>
        <TableCell style={{ ...centerText }}>{row.codiceUnivoco || ''}</TableCell>
      </ButtonBase >
    );
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
        Richieste Edil Connect
      </Typography>

      {companyLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box sx={{ overflowX: "auto" }}>
          <Paper sx={{ borderRadius: "10px", overflow: "hidden" }} variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={CellStyle}>ID Richiesta</TableCell>
                    <TableCell style={CellStyle}>Data Richiesta</TableCell>
                    <TableCell style={CellStyle}>Ragione Sociale</TableCell>
                    <TableCell style={CellStyle}>Data Codice Univoco</TableCell>
                    <TableCell style={CellStyle}>Codice Univoco</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{companies.map(renderTableRow)}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <Typography
              sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
            >
              {`${companyPage * rowsPerPage + 1} - ${companyPage * rowsPerPage + companies.length} di ${companyTotal}`}
            </Typography>
            <IconButton
              onClick={() => handleCompanyPage(false)}
              disabled={companyPage === 0}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => handleCompanyPage(true)}
              disabled={rowsPerPage * (companyPage + 1) >= companyTotal}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          {(statusMessageCompanies) && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                my: 1,
                mx: 2,
                flexGrow: 1,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
              }}
              role="alert"
            >
              {statusMessageCompanies}
            </Paper>
          )}
        </Box>
      )}
    </Container>
  );
};

export default OpEntiEdilConnect;
