import React, { useState, useEffect } from 'react';
import {
  Container, Paper, Grid, Typography, Box, Chip, TextField,
  Autocomplete, Select, MenuItem, InputLabel, FormControl,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  LinearProgress, Link, CircularProgress, Snackbar, Alert
} from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import utils from '../../utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  marginTop: theme.spacing(1),
  boxShadow: theme.shadows[3],
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StatusDot = styled(Box)(({ color }) => ({
  width: 12,
  height: 12,
  backgroundColor: color,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: 8,
}));

const Contabilita = () => {
  const [azienda, setAzienda] = useState(null);
  const [aziende, setAziende] = useState([]);
  const primoAnno = 2024;
  const annoCorrente = new Date().getFullYear();
  const anni = [];
  for (let a = primoAnno; a <= annoCorrente + 1; a++) {
    anni.push(a);
  }
  const [annoSelezionato, setAnnoSelezionato] = useState('all');
  const [parcelle, setParcelle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadData(annoSelezionato, azienda);
  }, [annoSelezionato, azienda]);

  useEffect(() => {
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
    if (process.env.REACT_APP_ENV == "test") {
      setAziende([{ id: 1, nome: 'Azienda Test' }]);
    }
  }

  const loadData = async (year, id) => {
    if (!year || !id) {
      return;
    }
    setError("");
    setLoading(true);
    try {
      const res = await axios.get(`/api/avvisiparcellaop?id=${id}&y=${year}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setParcelle(res.data.avvisi.map(a => {
          return {
            ...a,
            stato: getStato(a), importo: (a.tot_a_pagare / 100).toFixed(2),
            data: utils.revDate(a.data_documento.substring(0, 10))
          }
        }));
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const getStato = avviso => {
    if (avviso.pagato) {
      return 'Pagato';
    }
    if (Date.now() - avviso.confermato_at <= 15 * 24 * 60 * 60 * 1000) {
      return 'Da Pagare';
    }
    if (Date.now() - avviso.confermato_at <= 30 * 24 * 60 * 60 * 1000) {
      return 'In Scadenza';
    }
    return 'Scaduto';
  }

  const getPieChartData = () => {
    return [
      { name: 'Pagato', value: Number(parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#4caf50' },
      { name: 'In Scadenza', value: Number(parcelle.filter(p => p.stato === 'In Scadenza').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#ff9800' },
      { name: 'Da Pagare', value: Number(parcelle.filter(p => p.stato === 'Da Pagare').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#ffeb3b' },
      { name: 'Scaduto', value: Number(parcelle.filter(p => p.stato === 'Scaduto').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#f44336' }
    ];
  };

  const getPaymentCompletionPercentage = () => {
    const total = parcelle.reduce((sum, p) => sum + Number(p.importo), 0);
    const paid = parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0);
    return total === 0 ? 0 : (paid / total) * 100;
  };

  const getDotColor = (stato) => {
    switch (stato) {
      case 'Pagato': return '#4caf50'; // Green
      case 'Da Pagare': return '#ffeb3b'; // Yellow
      case 'In Scadenza': return '#ff9800'; // Orange
      case 'Scaduto': return '#f44336'; // Red
      default: return '#ccc'; // Gray
    }
  };

  return (
    <Container maxWidth="lg">
      <StyledPaper>
        <Typography variant="h4" gutterBottom>
          Contabilità
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="azienda"
              value={azienda}
              onChange={(event, newValue) => {
                setAzienda(newValue)
              }}
              options={aziende.map((az) => az.id)}
              getOptionLabel={(id) => {
                const a = aziende.filter(az => az.id == id)[0];
                return `${a.nome} (${a.id})`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Azienda"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Anno</InputLabel>
              <Select
                value={annoSelezionato}
                onChange={(e) => setAnnoSelezionato(e.target.value)}
                label="Anno"
                fullWidth
              >
                <MenuItem value="all">Tutti gli Anni</MenuItem>
                {anni.map((anno, index) => (
                  <MenuItem key={index} value={anno}>
                    {anno}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {loading && <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />}
        {azienda && !loading && (
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6} sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom>
                Stato dei Pagamenti
              </Typography>
              <Box sx={{ marginBottom: 3 }}>
                <StyledChip label="Pagato" sx={{ backgroundColor: '#4caf50', color: 'white' }} />
                <StyledChip label="Da Pagare" sx={{ backgroundColor: '#ffeb3b', color: 'black' }} />
                <StyledChip label="In Scadenza" sx={{ backgroundColor: '#ff9800', color: 'white' }} />
                <StyledChip label="Scaduto" sx={{ backgroundColor: '#f44336', color: 'white' }} />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#4caf50" />
                  Pagato: €{parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#ffeb3b" />
                  Da Pagare: €{parcelle.filter(p => p.stato === 'Da Pagare').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#ff9800" />
                  In Scadenza: €{parcelle.filter(p => p.stato === 'In Scadenza').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                  <StatusDot color="#f44336" />
                  Scaduto: €{parcelle.filter(p => p.stato === 'Scaduto').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)}
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Pagamenti Completati
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={getPaymentCompletionPercentage()}
                  sx={{ height: 10, borderRadius: 5 }}
                />
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {getPaymentCompletionPercentage().toFixed(2)}%
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PieChart width={330} height={330}>
                  <Pie
                    data={getPieChartData()}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {getPieChartData().map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Box>
            </Grid>
          </Grid>
        )}

      </StyledPaper>

      {error && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      {azienda && (
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Dettagli Avvisi di Parcella
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id Parcella</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Importo</TableCell>
                  <TableCell>Stato</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parcelle.map((avviso) => (
                  <TableRow key={avviso.id}>
                    <TableCell>
                      <Link href={`/op/amministrazione/avvisiparcella/detail?id=${avviso.id}`} underline="hover">
                        {avviso.id}
                      </Link>
                    </TableCell>
                    <TableCell>{avviso.data}</TableCell>
                    <TableCell>€{avviso.importo}</TableCell>
                    <TableCell>
                      <StatusDot color={getDotColor(avviso.stato)} />
                      {avviso.stato}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      )}
    </Container>
  );
};

export default Contabilita;
