import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ChatBotComponent() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState([]);
  const [loadedFiles, setLoadedFiles] = useState([]);
  const [botMaterial, setBotMaterial] = useState([]);

  useEffect(() => {
    async function fetchBotMaterial() {
      try {
        const file1 = await axios.get('file1.txt'); // Sostituisci con il percorso effettivo del tuo file
        const file2 = await axios.get('file2.txt'); // Sostituisci con il percorso effettivo del tuo file
        // Continua con tutti i file necessari
        const allFiles = [file1.data, file2.data]; // Salva i contenuti dei file
        setLoadedFiles(allFiles);
      } catch (error) {
        console.error('Errore nel caricare i file:', error);
      }
    }

    fetchBotMaterial();
  }, []);

  const sendMessage = async () => {
    if (inputText.trim() === '') return;
    const newMessages = [...messages, { text: inputText, fromUser: true }];
    setMessages(newMessages);
    setInputText('');

    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'text-davinci-003',
        messages: newMessages.map(message => ({
          role: message.fromUser ? 'user' : 'assistant',
          content: message.text
        })),
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_OPENAI_API_KEY',
        }
      });

      const botResponse = response.data.choices[0].message.content;
      const updatedMessages = [...newMessages, { text: botResponse, fromUser: false }];
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Errore nella richiesta al server:', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Richiedi Una Consulenza</h1>
      <div style={{ flexGrow: 1, overflowY: 'auto', padding: '10px' }}>
        {messages.map((message, index) => (
          <div key={index} style={{
            marginBottom: '10px',
            padding: '10px',
            borderRadius: '10px',
            maxWidth: '70%',
            alignSelf: message.fromUser ? 'flex-end' : 'flex-start',
            backgroundColor: message.fromUser ? '#DCF8C6' : '#E0E0E0',
          }}>
            {message.text}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Scrivi un messaggio..."
          style={{
            flexGrow: 1,
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #ccc',
            marginRight: '10px',
          }}
        />
        <button onClick={sendMessage} style={{
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '30%',
          padding: '10px',
          cursor: 'pointer',
        }}>
          <i className="material-icons">send</i>
        </button>
      </div>
    </div>
  );
}

export default ChatBotComponent;
