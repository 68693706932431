import React, { useState, useEffect } from "react";
import { Button, Avatar, Box, Typography, styled } from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import { Link } from "react-router-dom";
import OpEntiTab from "./OpEntiTab";

const buttonStyles = {
  width: "100%",
  marginBottom: "10px",
  transition: "transform 0.2s ease, filter 0.2s ease",
  background: "#333", // Colore di sfondo scuro predefinito
  color: "white", // Testo bianco predefinito
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  [theme.breakpoints.up("lg")]: {
    marginLeft: "10px",
    margin: "10px",
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "250px",
  padding: "20px",
  borderRadius: "10px",
  background: "linear-gradient(to bottom, #1e1e1e, #353535)",
  boxShadow: "0px 0px 5px 0px #888888",
  color: "white",
  margin: "10px auto",
  [theme.breakpoints.up("md")]: {
    marginLeft: "10px", // Aggiunto il margine solo in desktop
    marginTop: "20px",
    textAlign: "left", // Allineamento a sinistra in desktop
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center", // Allineamento al centro in mobile e tablet
  },
}));

const OpEnti = () => {
  const [hoveredDurc, setHoveredDurc] = useState(false);
  const [hoveredEdilConnet, setHoveredEdilConnet] = useState(false);
  const [hoveredNoteRettificaInps, setHoveredNoteRettificaInps] = useState(false);
  const [username, setUsername] = useState("");
  const avatarUrl = "url_dell_avatar";

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && !me.op) {
          window.location.href = "/cli";
          return;
        }
        if (success) {
          setUsername(`${me.nome.charAt(0).toUpperCase()}${me.nome.substring(1).toLowerCase()} ${me.cognome.charAt(0).toUpperCase()}.`);
          return;
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };


  return (
    <Container>
      <DashboardContainer>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Avatar
            alt={username}
            src={avatarUrl}
            style={{
              width: "50px",
              height: "50px",
              marginRight: "20px",
            }}
          />
          <Typography variant="h6" color="white">
            {username}
          </Typography>
        </Box>

        <Link to="/op/enti/durc" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredDurc ? "#123c54" : "#333",
              transform: hoveredDurc ? "scale(1.05)" : "scale(1)",
              filter: hoveredDurc ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredDurc(true)}
            onMouseLeave={() => setHoveredDurc(false)}
          >
            Archivio Durc
          </Button>
        </Link>

        <Link to="/op/enti/edilconnect" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredEdilConnet ? "#354d86" : "#333",
              transform: hoveredEdilConnet ? "scale(1.05)" : "scale(1)",
              filter: hoveredEdilConnet ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredEdilConnet(true)}
            onMouseLeave={() => setHoveredEdilConnet(false)}
          >
            Edil Connect
          </Button>
        </Link>

        <Link to="/op/enti/noterett" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredNoteRettificaInps ? "#646496" : "#333",
              transform: hoveredNoteRettificaInps ? "scale(1.05)" : "scale(1)",
              filter: hoveredNoteRettificaInps ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredNoteRettificaInps(true)}
            onMouseLeave={() => setHoveredNoteRettificaInps(false)}
          >
            Note Rettifica INPS
          </Button>
        </Link>

      </DashboardContainer>
      <OpEntiTab />
    </Container>
  );
};

export default OpEnti;