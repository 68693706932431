import React, { useState } from "react";
import { Divider, CircularProgress, Typography, TextField, Button, Paper, Box, Grid, Checkbox, FormControlLabel, IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import utils from "../../utils";

const CliEntiEdilConnect = () => {
  const [formData, setFormData] = useState({
    clientType: "",
    companyName: "",
    companyTaxCode: "",
    vatNumber: "",
    foreignCompany: false,
    companyAddress: "",
    province: "",
    city: "",
    cap: "",
    phone: "",
    email: "",
    pec: "",
    note: "",
    totalWorkAmount: "",
    startDate: null,
    endDate: null,
    activityPerformed: "",
    workDescription: "",
    edileAmount: "",

    freelanceWorker: false,
    ceSiteCode: "",
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [subContractors, setSubContractors] = useState([]);
  const emptySubData = {
    companyName: "",
    companyTaxCode: "",
    vatNumber: "",
    foreignCompany: false,
    companyAddress: "",
    province: "",
    city: "",
    cap: "",
    phone: "",
    email: "",
    pec: "",
    workDescription: "",
    startDate: null,
    endDate: null,
    activityPerformed: "",
    edileAmount: "",
    freelanceWorker: false,
    ceSiteCode: "",
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubContractorInputChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    const tmpSubContractos = [...subContractors];
    tmpSubContractos[index][name] = newValue;
    setSubContractors(tmpSubContractos);
  };

  const handleAddSubContractor = () => {
    setSubContractors([...subContractors, { ...emptySubData }]);
  };

  const handleRemoveSubContractor = (index) => {
    const updatedSubContractors = [...subContractors];
    updatedSubContractors.splice(index, 1);
    setSubContractors(updatedSubContractors);
  };

  const handleStartDateChange = (date) => {
    const filtered = filterDate(date);
    if (!filtered) {
      return;
    }
    setFormData({
      ...formData,
      startDate: filtered,
    });
  };

  const handleEndDateChange = (date) => {
    const filtered = filterDate(date);
    if (!filtered) {
      return;
    }
    setFormData({
      ...formData,
      endDate: filtered,
    });
  };

  const filterDate = (newValue) => {
    if (!newValue) {
      return null;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return null;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleSubContractorStartDateChange = (date, index) => {
    const filtered = filterDate(date);
    if (!filtered) {
      return;
    }
    const tmpSubData = subContractors;
    tmpSubData[index].startDate = filtered;
    setSubContractors(tmpSubData);
  }

  const handleSubContractorEndDateChange = (date, index) => {
    const filtered = filterDate(date);
    if (!filtered) {
      return;
    }
    const tmpSubData = subContractors;
    tmpSubData[index].endDate = filtered;
    setSubContractors(tmpSubData);
  }

  const theme = createTheme({
    typography: {
      fontSize: 10,
    },
  });

  const handleSubmit = async () => {
    setStatusMessage("");
    if (!formData.clientType || !formData.companyName) {
      setStatusMessage("Inserire i dati aziendali");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post(`/api/creaedilconnect`,
        { formData, subContractors },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        window.location.href = "/success?backto=%2Fcli%2Fenti";
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Grid container justifyContent="center" alignItems="center" style={{ height: "auto", marginTop: "20px" }}>
          <Grid item xs={12} md={9} xl={6}>
            <Paper elevation={4} style={{ padding: 20 }}>
              <Typography variant="h4" gutterBottom textAlign="center" style={{ margin: "2px" }}>
                Richiedi Edil Connect
              </Typography>

              <Divider sx={{ my: 2 }} />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                    Dati Aziendali
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <FormControl fullWidth variant="outlined" style={{ marginBottom: 5 }}>
                    <InputLabel id="client-type-label">Tipologia Committente</InputLabel>
                    <Select
                      labelId="client-type-label"
                      id="client-type"
                      name="clientType"
                      value={formData.clientType}
                      onChange={handleInputChange}
                      label="Tipologia Committente"
                    >
                      <MenuItem value="Privato Cittadino">Privato Cittadino</MenuItem>
                      <MenuItem value="Ente Pubblico/Stazione Appaltante">Ente Pubblico/Stazione Appaltante</MenuItem>
                      <MenuItem value="Azienda Privata (Giuridica)">Azienda Privata (Giuridica)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="companyName"
                    label="Ragione Sociale"
                    variant="outlined"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="companyTaxCode"
                    label="Codice Fiscale"
                    variant="outlined"
                    value={formData.companyTaxCode}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="vatNumber"
                    label="Partita Iva"
                    variant="outlined"
                    value={formData.vatNumber}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <FormControlLabel
                    control={<Checkbox name="foreignCompany" checked={formData.foreignCompany} onChange={handleInputChange} />}
                    label="Impresa Estera"
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                    Sede e Contatti
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="companyAddress"
                    label="Indirizzo e Sede Impresa"
                    variant="outlined"
                    value={formData.companyAddress}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="province"
                    label="Provincia"
                    variant="outlined"
                    value={formData.province}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="city"
                    label="Comune"
                    variant="outlined"
                    value={formData.city}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="cap"
                    label="Cap"
                    variant="outlined"
                    value={formData.cap}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="phone"
                    label="Telefono"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="email"
                    label="Mail"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="pec"
                    label="PEC"
                    variant="outlined"
                    value={formData.pec}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                    Dati del Cantiere
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <DatePicker
                    label="Data Inizio"
                    value={formData.startDate}
                    onChange={handleStartDateChange}
                    format="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <DatePicker
                    label="Data Fine"
                    value={formData.endDate}
                    onChange={handleEndDateChange}
                    format="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="totalWorkAmount"
                    label="Importo Complessivo Lavori"
                    variant="outlined"
                    value={formData.totalWorkAmount}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="workDescription"
                    label="Descrizione Lavori da Denunciare"
                    variant="outlined"
                    value={formData.workDescription}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="activityPerformed"
                    label="Attività Svolta"
                    variant="outlined"
                    value={formData.activityPerformed}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                  <TextField
                    name="note"
                    label="Note"
                    variant="outlined"
                    value={formData.note}
                    onChange={handleInputChange}
                    fullWidth
                    style={{ marginBottom: 5 }}
                  />
                </Grid>

                {subContractors.length > 0 && (
                  <Grid item xs={12}>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                      Subappaltatrici
                    </Typography>

                    {subContractors.map((subContractor, index) => (
                      <div key={index} style={{ marginBottom: 5 }}>
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveSubContractor(index)}
                          aria-label="delete"
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>

                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Azienda Sub
                          </Typography>

                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="companyName"
                                label="Ragione Sociale"
                                variant="outlined"
                                value={subContractor.companyName}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="companyTaxCode"
                                label="Codice Fiscale"
                                variant="outlined"
                                value={subContractor.companyTaxCode}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="vatNumber"
                                label="Partita Iva"
                                variant="outlined"
                                value={subContractor.vatNumber}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <FormControlLabel
                                control={<Checkbox name="foreignCompany" checked={subContractor.foreignCompany} onChange={(e) => handleSubContractorInputChange(e, index)} />}
                                label="Impresa Estera"
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                                Sede e Contatti
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="companyAddress"
                                label="Indirizzo e Sede Impresa"
                                variant="outlined"
                                value={subContractor.companyAddress}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="province"
                                label="Provincia"
                                variant="outlined"
                                value={subContractor.province}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="city"
                                label="Comune"
                                variant="outlined"
                                value={subContractor.city}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="cap"
                                label="Cap"
                                variant="outlined"
                                value={subContractor.cap}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="phone"
                                label="Telefono"
                                variant="outlined"
                                value={subContractor.phone}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="email"
                                label="Mail"
                                variant="outlined"
                                value={subContractor.email}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="pec"
                                label="PEC"
                                variant="outlined"
                                value={subContractor.pec}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                                Dati del Cantiere
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <DatePicker
                                label="Data Inizio Presunta"
                                value={subContractor.startDate}
                                onChange={(date) => handleSubContractorStartDateChange(date, index)}
                                format="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                sx={{ width: '100%' }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <DatePicker
                                label="Data Fine Presunta"
                                value={subContractor.endDate}
                                onChange={(date) => handleSubContractorEndDateChange(date, index)}
                                format="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                sx={{ width: '100%' }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="workDescription"
                                label="Descrizione Lavori Subappalto"
                                variant="outlined"
                                value={subContractor.workDescription}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="edileAmount"
                                label="Importo Edile"
                                variant="outlined"
                                value={subContractor.edileAmount}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <TextField
                                name="ceSiteCode"
                                label="Codice Cantiere CE"
                                variant="outlined"
                                value={subContractor.ceSiteCode}
                                onChange={(e) => handleSubContractorInputChange(e, index)}
                                fullWidth
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                              <FormControlLabel
                                control={<Checkbox name="freelanceWorker" checked={subContractor.freelanceWorker} onChange={(e) => handleSubContractorInputChange(e, index)} />}
                                label="Lavoratore Autonomo"
                                style={{ marginBottom: 1 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Divider sx={{ my: 2 }} />

                      </div>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddSubContractor}
                    style={{ marginBottom: 5 }}
                  >
                    Aggiungi Azienda Subappaltatrice
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" marginTop={2}>
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection="column"
            px={2}
          >
            {statusMessage && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessage}
              </Paper>
            )}
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              px={2}
            >
              {isLoading ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 2, px: 8 }}
                >
                  Invia
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default CliEntiEdilConnect;
