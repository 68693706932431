import React, { useState, useEffect } from 'react';
import { Divider, Select, Grid, FormControl, InputLabel, MenuItem, Stack, CircularProgress, Paper, Typography, Container, TextField, Badge } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import utils from "../../utils";


const ControlPanel = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chartDataNumeri, setChartDataNumeri] = useState([]);
  const [chartDataFatturato, setChartDataFatturato] = useState([]);

  const loadData = async (start, end) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/pannellostats?start=${start}&end=${end}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats } = res.data;
      if (success) {
        setChartDataNumeri(stats.barsNumeri);
        setChartDataFatturato(stats.barsFatturato);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleStartDateChange = (date) => {
    if (!date) {
      setStartDate(null);
      return;
    }
    if (date.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = date.$D.toString().padStart(2, "0");
    const month = (date.$M + 1).toString().padStart(2, "0");
    const year = date.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    if (endDate) {
      loadData(formattedDate, endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setEndDate(null);
      return;
    }
    if (date.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = date.$D.toString().padStart(2, "0");
    const month = (date.$M + 1).toString().padStart(2, "0");
    const year = date.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
    if (startDate) {
      loadData(startDate, formattedDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography variant="h4" margin={3} align="center" fontWeight="bold" gutterBottom>
          Pannello di Controllo
        </Typography>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DatePicker
                  label="Data Inizio"
                  format="DD/MM/YYYY"
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DatePicker
                  label="Data Fine"
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={handleEndDateChange}
                  required
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider style={{ margin: '12px 0' }} />

          <Typography variant="h5" margin={3} align="center" fontWeight="bold" gutterBottom>
            Pratiche totali ricevute
          </Typography>
          {loading ? (
            <Stack sx={{ my: 3 }} alignItems="center">
              <CircularProgress disableShrink />
            </Stack>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartDataNumeri} margin={{ right: 10, bottom: 60 }}>
                <XAxis dataKey="tipo" angle={-45} textAnchor="end" />
                <YAxis />
                <Tooltip />
                <Bar
                  dataKey={'completate'}
                  stackId="a"
                  fill={'#84a0d9'}
                />
                <Bar
                  dataKey={'non completate'}
                  stackId="a"
                  fill={'#d99284'}
                />
              </BarChart>
            </ResponsiveContainer>)}

          <Divider style={{ margin: '12px 0' }} />

          <Typography variant="h5" margin={3} align="center" fontWeight="bold" gutterBottom>
            Fatturato totale per pratica
          </Typography>
          {loading ? (
            <Stack sx={{ my: 3 }} alignItems="center">
              <CircularProgress disableShrink />
            </Stack>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartDataFatturato} margin={{ right: 10, bottom: 60 }}>
                <XAxis dataKey="tipo" angle={-45} textAnchor="end" />
                <YAxis />
                <Tooltip />
                <Bar
                  dataKey={'incassati'}
                  stackId="a"
                  fill={'#84a0d9'}
                />
                <Bar
                  dataKey={'non incassati'}
                  stackId="a"
                  fill={'#d99284'}
                />
              </BarChart>
            </ResponsiveContainer>)}

          {statusMessage && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                mt: 2,
                ml: 3,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
                width: "100%",
              }}
              role="alert"
            >
              {statusMessage}
            </Paper>
          )}

        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default ControlPanel;
