const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return '';
};

const getAuthHeaders = () => {
  const jwt = sessionStorage.getItem('jwt') || getCookie('jwt') || '';
  return { 'Authorization': `Bearer ${jwt}` };
}

const formatData = (ts) => {
    const date = new Date(ts);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substring(2)}`
}

function revDate(dateStr) {
  if (!dateStr) {
    return dateStr;
  }
  return dateStr.substring(8, 10) + '/' + dateStr.substring(5, 7) + '/' + dateStr.substring(0, 4);
}

export default {
  getAuthHeaders,
  formatData,
  revDate,
}
