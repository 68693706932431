import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import { Typography } from "@mui/material";
import utils from "../../utils";
import axios from "axios";
import logo from "../../logo.svg";
import { FiCalendar, FiFileText, FiBook, FiUser, FiLogIn, FiLogOut, FiBookOpen } from "react-icons/fi";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false); // Chiude il menu a tendina quando un link viene cliccato
  };

  const userType = sessionStorage.getItem("usertype");

  const logout = async () => {
    try {
      const res = await axios.post(
        "/api/logout",
        {},
        { headers: utils.getAuthHeaders(), validateStatus: () => true }
      );
      if (res.status === 200) {
        const { success, error } = res.data;
        if (success) {
          sessionStorage.removeItem("jwt");
          sessionStorage.removeItem("usertype");
          document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          window.location.href = "/";
          return;
        } else if (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <nav className="navbar" id="navbar-item">
      <div className="brand">
        <Link to="/" onClick={handleLinkClick}>
          <img src={logo} alt="Logo" />
        </Link>{" "}
      </div>
      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
      {/* {userType === "op" && (
          <Link to="/formazione" onClick={handleLinkClick}>
            <FiBookOpen /> Formazione
          </Link>
        )}*/}
        <Link to="/scadenze" onClick={handleLinkClick}>
          <FiCalendar /> Scadenze
        </Link>
        <Link to="/moduli" onClick={handleLinkClick}>
          <FiFileText /> Moduli
        </Link>
        <Link to="/blog" onClick={handleLinkClick}>
          <FiBook /> Blog
        </Link>
        {userType ? (
          <Link to={userType === "op" ? "/op" : "/cli"} onClick={handleLinkClick}>
            <FiUser /> Area {userType === "op" ? "Op" : "Clienti"}
          </Link>
        ) : (
          <Typography />
        )}
        {userType ? (
          <Link onClick={logout}>
            <FiLogOut /> Logout
          </Link>
        ) : (
          <Link to="/login" onClick={handleLinkClick}>
            <FiLogIn /> Login
          </Link>
        )}

      </div>
      <div className="menu-icon" onClick={handleMenuClick}>
        <div className="hamburger-icon">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
