import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Container,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";

const DettagliAzienda = () => {
  const [searchParams] = useSearchParams();
  const [azienda, setAzienda] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAzienda, setEditedAzienda] = useState({});
  const [statusMessage, setStatusMessage] = useState('');

  const id = searchParams.get("id");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          try {
            const res = await axios.get(`/api/getuser?id=${id}`, {
              headers: utils.getAuthHeaders(),
            });
            const { success } = res.data;
            if (success) {
              setAzienda(res.data.user);
              setEditedAzienda(res.data.user);
            }
          } catch (error) {
            console.log(error);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
          }
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
    setIsLoading(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAzienda({ ...editedAzienda, [name]: value });
  };

  const handleConfirmClick = async () => {
    setStatusMessage("");
    try {
      const res = await axios.post(`/api/updateutente`,
        { ...editedAzienda, id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  };

  return (
    <Container center maxWidth="md">
      <Paper elevation={3} sx={{ borderRadius: "8px", padding: "14px", my: 2 }}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Dettagli Azienda
        </Typography>
        {isLoading ? (
          <CircularProgress disableShrink />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Nome:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="nome"
                  value={editedAzienda.nome}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.nome}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Cognome:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="cognome"
                  value={editedAzienda.cognome}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.cognome}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Ragione Sociale:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="ragione_sociale"
                  value={editedAzienda.ragione_sociale}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.ragione_sociale}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Telefono:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="telefono"
                  value={editedAzienda.telefono}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.telefono}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Partita IVA:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="partita_iva"
                  value={editedAzienda.partita_iva}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.partita_iva}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Indirizzo:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="indirizzo"
                  value={editedAzienda.indirizzo}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.indirizzo}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Città:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="citta"
                  value={editedAzienda.citta}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.citta}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                CAP:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="cap"
                  value={editedAzienda.cap}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.cap}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Provincia:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="provincia"
                  value={editedAzienda.provincia}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.provincia}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Email:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{azienda.email}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                PEC:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="pec"
                  value={editedAzienda.pec}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.pec}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Commercialista:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <TextField
                  name="commercialista"
                  value={editedAzienda.commercialista}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body1">{azienda.commercialista}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Utente Master:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{azienda.is_master ? "Si" : "No"}</Typography>
            </Grid>

            {azienda.master && <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Master:
              </Typography>
            </Grid>}
            {azienda.master && <Grid item xs={6}>
              <Typography variant="body1">
                <Link href={`/op/dettaglioazienda?id=${azienda.master}`}>
                  {azienda.masterName}
                </Link>
              </Typography>
            </Grid>}

            <Grid item xs={12} sm={6}>
              {isEditing ? (
                <Button onClick={handleConfirmClick} variant="contained" color="primary">
                  Conferma
                </Button>
              ) : (
                <Button onClick={handleEditClick} variant="contained" color="primary">
                  Modifica
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        {statusMessage && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}
      </Paper>
    </Container>
  );
};

export default DettagliAzienda;
