import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import utils from "../../utils";

const WhatsappButton = () => {
  const [op, setOp] = useState(false);
  const whatsappUrl = "https://wa.me/390458201309";
  const whatsappOpUrl = "https://web.whatsapp.com/";

  useEffect(() => {
    loadMe();
  }, []);

  const loadMe = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          setOp(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openWhatsAppPopup = () => {
    window.open(op ? whatsappOpUrl : whatsappUrl, '_blank');
  };

  return (
    <IconButton
      sx={{
        position: 'fixed',
        bottom: '5px',
        right: '1px',
        zIndex: 1000,
      }}
      onClick={openWhatsAppPopup}
      aria-label="WhatsApp"
    >
      <img
        src="/img/whats.svg"
        alt="WhatsApp"
        className="whatsapp-icon"
        style={{
          width: '40px',
          height: '40px',
        }}
      />
    </IconButton>
  );
};

export default WhatsappButton;
