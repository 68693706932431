import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider, Collapse, IconButton, Stack, CircularProgress } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { FiSettings, FiFileText, FiDollarSign, FiLayers } from 'react-icons/fi'; // Import delle icone Fi
import axios from "axios";
import utils from "../../utils";

const CliDomande = () => {
  const [faqs, setFaqs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openCategoryIndex, setOpenCategoryIndex] = useState(null);
  const [openFAQs, setOpenFAQs] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadFaqs();
  }, []);

  const loadFaqs = async () => {
    try {
      const res = await axios.get(`/api/faqs`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, faqs } = res.data;
      if (success) {
        const storedFaqs = faqs;
        const categorySet = new Set(storedFaqs.map(faq => faq.category));
        const sortedCategories = Array.from(categorySet).sort();
        setCategories(sortedCategories);
        setFaqs(storedFaqs);
        setOpenFAQs(new Array(sortedCategories.length).fill([]).map(() => new Array(storedFaqs.length).fill(false)));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleClickCategory = (index) => {
    setOpenCategoryIndex(openCategoryIndex === index ? null : index);
  };

  const handleClickFAQ = (catIndex, faqIndex) => {
    const newOpenFAQs = [...openFAQs];
    newOpenFAQs[catIndex][faqIndex] = !newOpenFAQs[catIndex][faqIndex];
    setOpenFAQs(newOpenFAQs);
  };

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box my={4} textAlign="left">
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>Domande Frequenti</Typography>
          <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'center', marginBottom: '50px' }}>
            Qui troverai tutte le domande più frequenti fatte allo studio con le relative risposte.
          </Typography>
          {categories.map((category, catIndex) => (
            (category !== "Categoria 1" && category !== "Categoria 2") && (
              <React.Fragment key={catIndex}>
                <Box mt={2}>
                  <Typography variant="h5" gutterBottom onClick={() => handleClickCategory(catIndex)} style={{ cursor: 'pointer' }}>
                    <IconButton sx={{ marginRight: '10px' }}>
                      {openCategoryIndex === catIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {getCategoryIcon(category)} {/* Icona della categoria */}
                    {category}
                  </Typography>
                  <Collapse in={openCategoryIndex === catIndex} timeout="auto" unmountOnExit>
                    <List>
                      {faqs.filter((faq) => faq.category === category).map((item, faqIndex) => (
                        <React.Fragment key={faqIndex}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleClickFAQ(catIndex, faqIndex)}>
                                  {faqIndex + 1}. {item.question}
                                </Typography>
                              }
                              secondary={
                                <Collapse in={openFAQs[catIndex][faqIndex]} timeout="auto" unmountOnExit>
                                  <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>{item.answer}</Typography>
                                </Collapse>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Collapse>
                </Box>
                {catIndex !== categories.length - 1 && <Divider />}
              </React.Fragment>
            )
          ))}
        </Box>)}
    </Container>
  );
};

// Funzione per ottenere l'icona della categoria
const getCategoryIcon = (category) => {
  switch (category) {
    case 'Contrattualistica':
      return <FiFileText />;
    case 'Amministrazione':
      return <FiSettings />;
    case 'Paghe':
      return <FiDollarSign />;
    case 'Varie':
      return <FiLayers />;
    default:
      return null;
  }
};

export default CliDomande;
