import React, { useState, useEffect } from "react";
import {
  Button, Typography, Box, styled, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  FormControl,
  Select,
  Divider
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import CliEntiTab from "./CliEntiTab";
import CliEntiTabEdil from "./CliEntiTabEdil";
import CliEntiTabRettInps from "./CliEntiTabRettInps";

const buttonStyles = {
  width: "100%",
  marginBottom: "10px",
  transition: "transform 0.2s ease, filter 0.2s ease",
  background: "#333", // Colore di sfondo scuro predefinito
  color: "white", // Testo bianco predefinito
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center", // Centra gli elementi orizzontalmente
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "250px",
  padding: "20px",
  borderRadius: "10px",
  background: "linear-gradient(to bottom, #1e1e1e, #353535)",
  boxShadow: "0px 0px 5px 0px #888888",
  color: "white",
  margin: "10px auto",
  [theme.breakpoints.up("md")]: {
    marginLeft: "10px",
    margin: "10px",
    textAlign: "left",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const CliEnti = () => {
  const [hoveredDurc, setHoveredDurc] = useState(false);
  const [hoveredCassaEdile, setHoveredCassaEdile] = useState(false);
  const [hoveredNoteRettificaInps, setHoveredNoteRettificaInps] = useState(false);
  const [selectedTable, setSelectedTable] = useState("CliEntiTab");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          window.location.href = "/op";
          return;
        }
        if (success) {
          return; // rimani sulla pagina
        }
      }
    } catch (_) {}
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login?backto=%2Fcli%2Fenti";
    }
  };

  const handleRichiediDurc = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmRichiediDurc = async (e) => {
    e.preventDefault();
    setDialogOpen(false);
    setStatusMessage("");
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/creadurc`, {},
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Container>
      <DashboardContainer>
        <Typography variant="h5" align="center" gutterBottom mt={1}>
          Richieste
        </Typography>

        <Button
          variant="contained"
          style={{
            ...buttonStyles,
            background: hoveredDurc ? "#123c54" : "#333",
            transform: hoveredDurc ? "scale(1.05)" : "scale(1)",
            filter: hoveredDurc ? "brightness(1.2)" : "brightness(1)",
          }}
          onMouseEnter={() => setHoveredDurc(true)}
          onMouseLeave={() => setHoveredDurc(false)}
          color="primary"
          onClick={handleRichiediDurc}
          sx={{ mb: 4 }}
        >
          Richiedi Durc
        </Button>

        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Conferma Richiesta Durc</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sei sicuro di voler chiedere il Durc?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Annulla
            </Button>
            <Button onClick={handleConfirmRichiediDurc} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

        <Link to="/cli/enti/edilconnect" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredCassaEdile ? "#354d86" : "#333",
              transform: hoveredCassaEdile ? "scale(1.05)" : "scale(1)",
              filter: hoveredCassaEdile ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredCassaEdile(true)}
            onMouseLeave={() => setHoveredCassaEdile(false)}
          >
            Edil Connect
          </Button>
        </Link>

        <Link to="/cli/enti/noterett" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredNoteRettificaInps ? "#646496" : "#333",
              transform: hoveredNoteRettificaInps ? "scale(1.05)" : "scale(1)",
              filter: hoveredNoteRettificaInps ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredNoteRettificaInps(true)}
            onMouseLeave={() => setHoveredNoteRettificaInps(false)}
          >
            Note Rettifica Inps
          </Button>
        </Link>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" sx={{ marginBottom: '10px', textAlign: 'center' }}>Richieste in Corso</Typography>
        <FormControl sx={{ minWidth: 200, backgroundColor: "#f5f5f5", textAlign: 'center', borderRadius: '10px', display: 'flex', flexDirection: 'column' }}>
          <Select
            value={selectedTable}
            onChange={(e) => setSelectedTable(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Seleziona Tabella' }}
            sx={{ textAlign: 'center', borderRadius: '10px' }} // Bordi arrotondati per il Select
          >
            <MenuItem value="CliEntiTab">Durc</MenuItem>
            <MenuItem value="CliEntiTabEdil">Edil Connect</MenuItem>
            <MenuItem value="CliEntiTabRettInps">Note Rettifica Inps</MenuItem>
          </Select>
        </FormControl>

      </DashboardContainer>

      <Box sx={{ width: "100%", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        {selectedTable === "CliEntiTab" && <CliEntiTab statusMessage={statusMessage} isLoading={isLoading} />}
        {selectedTable === "CliEntiTabEdil" && <CliEntiTabEdil statusMessage={statusMessage} isLoading={isLoading} />}
        {selectedTable === "CliEntiTabRettInps" && <CliEntiTabRettInps statusMessage={statusMessage} isLoading={isLoading} />}
      </Box>
      
    </Container>
  );
};

export default CliEnti;
