import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Divider,
  Box,
  Paper,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';

const videoLinks = {
  software: [
    { id: 1, title: 'Video Tutorial Software', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' },
  ],
  sicurezza: [
    { id: 1, title: 'Video Tutorial Sicurezza', url: 'https://www.youtube.com/embed/9bZkp7q19f0' },
  ],
  gestione: [
    { id: 1, title: 'Video Tutorial Gestione', url: 'https://www.youtube.com/embed/tVj0ZTS4WF4' },
  ],
};

const Formazione = () => {
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(Object.keys(videoLinks));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchChange = (event, newValue) => {
    setSearch(newValue);
    const lowercasedValue = newValue.toLowerCase();
    setFilteredCategories(
      Object.keys(videoLinks).filter((category) =>
        category.toLowerCase().includes(lowercasedValue)
      )
    );
  };

  return (
    <Container maxWidth="lg" style={{ padding: '20px' }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{ textAlign: 'center', marginBottom: '40px', color: '#000000' }}
      >
        Formazione
      </Typography>

      {/* Search bar with autocomplete */}
      <Autocomplete
        freeSolo
        options={Object.keys(videoLinks)}
        onInputChange={handleSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cerca sezione"
            margin="normal"
            fullWidth
          />
        )}
        value={search}
      />

      {filteredCategories.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px' }}>
          Nessun risultato trovato
        </Typography>
      ) : (
        filteredCategories.map((category) => (
          <Accordion
            key={category}
            expanded={expanded === category}
            onChange={handleChange(category)}
            sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${category}-content`}
              id={`${category}-header`}
              sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
            >
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <PlayCircleOutlineIcon sx={{ marginRight: '8px' }} />
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
              {videoLinks[category].map((video, index) => (
                <Box key={video.id} sx={{ marginBottom: '20px' }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
                    {video.title}
                  </Typography>
                  <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                    <iframe
                      width="100%"
                      height="315"
                      src={video.url}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Paper>
                  {index < videoLinks[category].length - 1 && (
                    <Divider sx={{ margin: '20px 0' }} />
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Container>
  );
};

export default Formazione;
