import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiMail, FiPhone, FiMapPin, FiUser } from "react-icons/fi";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Position.css";

const companyDetails = {
  name: "Studio Sonia Romeo",
  emails: [
    "paghe@studiosoniaromeo.it",
    "amministrazione@studiosoniaromeo.it",
  ].join(", "),
  phone: "045 8201309",
  partitaIVA: "02728160231",
  address: "Via Roveggia, 122/A 37136 Verona VR",
  socialMediaLinks: [
    { icon: faFacebook, url: "https://www.facebook.com/studiosoniaromeo" },
    {
      icon: faLinkedin,
      url: "https://www.linkedin.com/in/studio-romeo-31a2a5281/",
    },
    { icon: faInstagram, url: "https://www.instagram.com/studiosoniaromeo" },
    { icon: faWhatsapp, url: "https://wa.me/390458201309" },
  ],
};

const Position = () => {
  return (
    <div className="position-container">
      <h1>Dove Trovarci</h1>
      <div className="columns">
        <div className="contacts-column">
          <h3>
            <strong>{companyDetails.name}</strong>
          </h3>
          <p>
            <FiMail /> <strong>Email:</strong>{" "}
            <a
              href={`mailto:${companyDetails.emails}`}
              style={{ textDecoration: "none" }}
            >
              <i>{companyDetails.emails}</i>
            </a>
          </p>
          <p>
            <FiPhone /> <strong>Telefono:</strong> {companyDetails.phone}
          </p>
          <p>
            <FiUser /> <strong>Partita IVA:</strong> {companyDetails.partitaIVA}
          </p>
          <p>
            <FiMapPin /> <strong>Indirizzo:</strong> {companyDetails.address}
          </p>
          {/* Social Media Icons */}
          <div className="social-media-icons">
            {companyDetails.socialMediaLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={link.icon} />
              </a>
            ))}
          </div>
        </div>
        <div className="map-column">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2294.0022118933143!2d10.962081610931488!3d45.410469636902704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781e02a099ad5d3%3A0xd09f581dbb61cc0!2sVia%20Roveggia%2C%20122%2FA%2C%2037136%20Verona%20VR!5e1!3m2!1sit!2sit!4v1704313417547!5m2!1sit!2sit%22&gestureHandling=none"
            width="80%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Position;
