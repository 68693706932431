import React from "react";
import "./Video.css";

const Video = () => {
  return (
    <div className="video-container">
      <div className="video-overlay"></div>
      <video className="video" autoPlay loop muted>
        <source src={process.env.PUBLIC_URL + "/video.mp4"} type="video/mp4" />
        Il tuo browser non supporta il tag video.
      </video>
      <div className="wave-overlay"></div>
      <div className="text-overlay">
        <h1>Consulente del Lavoro</h1>
        <p className="text-animation">Studio Romeo</p>
        <p className="text-hidden">SR</p>
      </div>
    </div>
  );
};

export default Video;