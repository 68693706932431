// SuccessPage.js
import { useSearchParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("backto") || '/cli';

  const handleReturn = () => {
    window.location.href = redirect;
  };

  return (
    <div style={styles.container}>
      <CheckCircleIcon style={styles.icon} />
      <Typography variant="h5" style={styles.message}>
        Richiesta Inviata Con Successo
      </Typography>
      <Button variant="contained" color="primary" onClick={handleReturn}>
        Torna Indietro
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  icon: {
    color: 'green',
    fontSize: 64,
    marginBottom: 16,
  },
  message: {
    textAlign: 'center',
    marginBottom: 16,
  },
};

export default SuccessPage;
