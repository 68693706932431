import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Divider
} from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import { PendingOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // Importa useNavigate

const COLORS = ["#0088a8", "#035974"];

const OpPagChart = () => {
  const navigate = useNavigate();

  const now = new Date();
  const currentMonth = now.getMonth();
  const yearMap = {};
  yearMap[currentMonth] = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  yearMap[previousMonth] = currentMonth === 0 ? now.getFullYear() - 1 : now.getFullYear();

  const [presenzeData, setPresenzeData] = useState([]);
  const [pagheData, setPagheData] = useState([]);
  const [todoPaghe, setTodoPaghe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(previousMonth);

  useEffect(() => {
    loadCharts();
  }, [selectedMonth]);

  const loadCharts = async () => {
    try {
      const res = await axios.get(`/api/presenzestats?anno=${yearMap[selectedMonth]}&mese=${selectedMonth}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats, todo } = res.data;
      if (success) {
        setPresenzeData([{ name: "Ricevute", value: stats.ricevute }, { name: "In attesa", value: stats.daRicevere }]);
        setPagheData([{ name: "Completate", value: stats.completate }, { name: "Da fare", value: stats.daCompletare }]);
        setTodoPaghe(todo);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const handlePieClick = (data, index) => {
    if (data.name === "Completate") {
      navigate("/op/paghe/paghefatte");
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="center" gutterBottom>
          Presenze
        </Typography>
        <div style={{ height: 200 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={presenzeData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={60}
                  paddingAngle={5}
                  cornerRadius={5}
                  fill="#8884d8"
                  label
                >
                  {presenzeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          style={{ fontStyle: 'italic', color: 'grey' }}
        >
          Presenze ricevute e in attesa
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="center" gutterBottom>
          Paghe
        </Typography>
        <div style={{ height: 200 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pagheData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={60}
                  paddingAngle={5}
                  cornerRadius={5}
                  fill="#8884d8"
                  label
                  onClick={handlePieClick}
                >
                  {pagheData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          style={{ fontStyle: 'italic', color: 'grey' }}
        >
          Paghe Completate e Da Fare
        </Typography>
      </Grid>
      <Grid item xs={12}>

        <Divider sx={{ my: 2 }} />

        <Grid container justifyContent="center" alignItems="center" marginTop={3}>
          <Typography variant="h5" align="center" gutterBottom>
            Paghe da Fare
          </Typography>
          <FormControl style={{ marginLeft: 16 }}>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              style={{ minWidth: 200, textAlign: 'center' }}
            >
              <MenuItem value={currentMonth}>
                {new Date(0, currentMonth).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())} (Corrente)
              </MenuItem>
              <MenuItem value={previousMonth}>
                {new Date(0, previousMonth).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())} (Elaborazione)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <TableContainer style={{ borderRadius: '8px', marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#333' }}>
                <TableCell style={{ color: '#fff', width: '25%' }}>Azienda</TableCell>
                <TableCell style={{ color: '#fff', width: '25%' }}>Numero Dipendenti</TableCell>
                <TableCell style={{ color: '#fff', width: '25%' }}>Presa in Carico da</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todoPaghe.map((row) => (
                <TableRow key={row.nome}>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.dipendenti}</TableCell>
                  <TableCell>{row.presaInCarico ? row.presaInCarico : ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default OpPagChart;