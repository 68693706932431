import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/home/Home';
import Login from './components/home/Login';
import Moduli from './components/home/Moduli';
import Blog from './components/home/Blog';
import Privacy from './components/home/Privacy';
import Scadenze from './components/home/Scadenze';
import SuccessPage from './components/home/SuccessPage';
import Nav from './components/home/Nav';
import Footer from "./components/home/Footer";
import WhatsappButton from './components/home/WhatsappButton';
import PagamentoOk from './components/PagamentoOk';
import PagamentoKo from './components/PagamentoKo';
import Formazione from './components/home/Formazione';


import Cli from './components/cliente/Cli';

import CliContratt from './components/cliente/CliContratt';
import CliAmm from './components/cliente/CliAmm';
import CliPaghe from './components/cliente/CliPaghe';
import CliEnti from './components/cliente/CliEnti';
import CliPrevisioneCosti from './components/cliente/CliPrevisioneCosti';
import CliDomande from './components/cliente/CliDomande';
import PrenotaSala from './components/cliente/PrenotaSala';
import PrenotaCBA from './components/cliente/PrenotaCba';
import CliGuida from './components/cliente/CliGuida';

import ContrAssunzione from './components/cliente/ContrAssunzione';
import ContrCessazione from './components/cliente/ContrCessazione';
import ContrProroghe from './components/cliente/ContrProroghe';
import ContrTrasform from './components/cliente/ContrTrasform';
import ContrInfortunio from './components/cliente/ContrInfortunio';
import ContrContestazioni from './components/cliente/ContrContestazioni';
import ContrDistacco from './components/cliente/ContrDistacco';
import ContrDistaccoEst from './components/cliente/ContrDistaccoEst';
import ContrPraticheGeneriche from './components/cliente/ContrPraticheGeneriche';

import CliAmmAvvisiParcella from './components/cliente/CliAmmAvvisiParcella'
import CliAmmFatture from './components/cliente/CliAmmFatture';

import CliEntiEdilConnect from './components/cliente/CliEntiEdilConnect';
import CliEntiNoteRett from './components/cliente/CliEntiNoteRett';

import PagPresenze from './components/cliente/PagPresenze';
import PagBustePaga from './components/cliente/PagBustePaga';
import PagMalattia from './components/cliente/PagMalattia';
import PagCassaInteg from './components/cliente/PagCassaInteg';


import Op from './components/operatore/Op';

import OpContrattualistica from './components/operatore/OpContrattualistica';
import OpAmm from './components/operatore/OpAmm';
import OpPaghe from './components/operatore/OpPaghe';
import OpEnti from './components/operatore/OpEnti';
import OpDomande from './components/operatore/OpDomande';
import OpPrenotaSala from './components/operatore/OpPrenotaSala';
import OpPrenotaCba from './components/operatore/OpPrenotaCba';
import OpGuida from './components/operatore/OpGuida';

import OpAssunzione from './components/operatore/OpAssunzione';
import OpCessazione from './components/operatore/OpCessazione';
import OpProroghe from './components/operatore/OpProroghe';
import OpTrasformazione from './components/operatore/OpTrasformazione';
import OpInfortunio from './components/operatore/OpInfortunio';
import OpContestazioni from './components/operatore/OpContestazioni';
import OpDistacco from './components/operatore/OpDistacco';
import OpDistacchiEsteri from './components/operatore/OpDistacchiEsteri';
import OpPraticheGeneriche from './components/operatore/OpPraticheGeneriche';
import OpAnnullamenti from './components/operatore/OpAnnullamenti';

import DetAssunzione from "./components/operatore/ticketdetail/DetAssunzione";
import DetCessazione from "./components/operatore/ticketdetail/DetCessazione";
import DetProroghe from "./components/operatore/ticketdetail/DetProroghe";
import DetTrasformazione from "./components/operatore/ticketdetail/DetTrasformazione";
import DetMalattia from "./components/operatore/ticketdetail/DetMalattia";
import DetCassaIntegrazione from "./components/operatore/ticketdetail/DetCassaIntegrazione";
import DetInfortunio from "./components/operatore/ticketdetail/DetInfortunio";
import DetContestazioni from "./components/operatore/ticketdetail/DetContestazione";
import DetDistacco from "./components/operatore/ticketdetail/DetDistacco";
import DetDistaccoEst from "./components/operatore/ticketdetail/DetDistaccoEst";
import DetPraticheGeneriche from "./components/operatore/ticketdetail/DetPraticheGeneriche";
import DetAnnullamenti from "./components/operatore/ticketdetail/DetAnnullamento";

import OpAmmListaAziende from "./components/operatore/OpAmmListaAziende";
import OpAmmAvvisiParcella from "./components/operatore/OpAmmAvvisiParcella";
import OpAmmAvvisiParcellaDet from './components/operatore/OpAmmAvvisiParcellaDet';
import OpAmmFatture from "./components/operatore/OpAmmFatture";
import OpAmmPraticheArchiviate from "./components/operatore/OpAmmPraticheArchiviate";

import OpPagPresenze from './components/operatore/OpPagPrezenze';
import OpPagBustePaghe from './components/operatore/OpPagBustePaghe';
import OpPagheFatte from './components/operatore/OpPagheFatte';
import OpPagMalattia from './components/operatore/OpPagMalattia';
import OpPagCassaIntegrazione from './components/operatore/OpPagCassaIntegrazione';

import OpEntiArc from './components/operatore/OpEntiArc';
import OpEntiEdilConnect from './components/operatore/OpEntiEdilConnect';
import OpEntiEdilConnectDet from './components/operatore/OpEntiEdilConnectDet';
import OpEntiNoteRett from './components/operatore/OpEntiNoteRett';

import OpPannelloControllo from './components/operatore/OpPannelloControllo';
import OpOperazioni from './components/operatore/OpOperazioni';
import OpAmmFatturato from './components/operatore/OpAmmFatturato';
import OpSituazioneGenerale from './components/operatore/OpSituazioneGenerale';
import OpContabilita from './components/operatore/OpContabilita';
import OpNews from './components/operatore/OpNews';

import DettaglioAzienda from "./components/operatore/DettaglioAzienda";

import WorkinProgress from "./components/WorkinProgress";

const App = () => {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Nav />
        <Box component="main" sx={{ flexGrow: 1, mb: 3, display: 'flex', flexDirection: 'column' }}> 
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/moduli" element={<WorkinProgress />} />
            <Route path="/blog" element={<WorkinProgress />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/scadenze" element={<Scadenze />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/pagamento/ok" element={<PagamentoOk />} />
            <Route path="/pagamento/ko" element={<PagamentoKo />} />
            <Route path="/formazione" element={<Formazione />} />


            <Route path="/cli" element={<Cli />} />

            <Route path="/cli/contrattualistica" element={<CliContratt />} />
            <Route path="/cli/amministrazione" element={<CliAmm />} />
            <Route path="/cli/paghe" element={<CliPaghe />} />
            <Route path="/cli/enti" element={<CliEnti />} />
            <Route path="/cli/previsionecosti" element={<WorkinProgress />} />
            <Route path="/cli/domande" element={<CliDomande />} />
            <Route path="/cli/prenotasala" element={<WorkinProgress />} />
            <Route path="/cli/prenotacba" element={<PrenotaCBA />} />
            <Route path="/cli/guida" element={<CliGuida />} />

            <Route path="/cli/contrattualistica/assunzione" element={<ContrAssunzione />} />
            <Route path="/cli/contrattualistica/cessazione" element={<ContrCessazione />} />
            <Route path="/cli/contrattualistica/proroga" element={<ContrProroghe />} />
            <Route path="/cli/contrattualistica/trasformazione" element={<ContrTrasform />} />
            <Route path="/cli/contrattualistica/infortunio" element={<ContrInfortunio />} />
            <Route path="/cli/contrattualistica/contestazione" element={<ContrContestazioni />} />
            <Route path="/cli/contrattualistica/distacco" element={<ContrDistacco />} />
            <Route path="/cli/contrattualistica/distaccoestero" element={<ContrDistaccoEst />} />
            <Route path="/cli/contrattualistica/vardatori" element={<WorkinProgress />} />
            <Route path="/cli/contrattualistica/praticagenerica" element={<ContrPraticheGeneriche />} />

            <Route path="/cli/amministrazione/avvisiparcella" element={<CliAmmAvvisiParcella />} />
            <Route path="/cli/amministrazione/fatture" element={<CliAmmFatture />} />

            <Route path="/cli/enti/edilconnect" element={<CliEntiEdilConnect />} />
            <Route path="/cli/enti/noterett" element={<CliEntiNoteRett />} />

            <Route path="/cli/presenze" element={<PagPresenze />} />
            <Route path="/cli/bustepaga" element={<WorkinProgress />} />
            <Route path="/cli/malattia" element={<PagMalattia />} />
            <Route path="/cli/cassaintegrazione" element={<PagCassaInteg />} />


            <Route path="/op" element={<Op />} />

            <Route path="/op/contrattualistica" element={<OpContrattualistica />} />
            <Route path="/op/amministrazione" element={<OpAmm />} />
            <Route path="/op/paghe" element={<OpPaghe />} />
            <Route path="/op/enti" element={<OpEnti />} />
            <Route path="/op/costi" element={<WorkinProgress />} />
            <Route path="/op/domande" element={<OpDomande />} />
            <Route path="/op/prenotasala" element={<OpPrenotaSala />} />
            <Route path="/op/prenotacba" element={<WorkinProgress />} />
            <Route path="/op/guida" element={<OpGuida />} />

            <Route path="/op/contrattualistica/assunzione" element={<ContrAssunzione op={true}/>} />
            <Route path="/op/contrattualistica/cessazione" element={<ContrCessazione op={true} />} />
            <Route path="/op/contrattualistica/proroga" element={<ContrProroghe op={true} />} />
            <Route path="/op/contrattualistica/trasformazione" element={<ContrTrasform op={true} />} />
            <Route path="/op/contrattualistica/infortunio" element={<ContrInfortunio op={true} />} />
            <Route path="/op/contrattualistica/contestazione" element={<ContrContestazioni op={true} />} />
            <Route path="/op/contrattualistica/distacco" element={<ContrDistacco op={true} />} />
            <Route path="/op/contrattualistica/distaccoestero" element={<ContrDistaccoEst op={true} />} />
            <Route path="/op/contrattualistica/praticagenerica" element={<ContrPraticheGeneriche op={true} />} />

            <Route path="/op/assunzioni" element={<OpAssunzione />} />
            <Route path="/op/cessazioni" element={<OpCessazione />} />
            <Route path="/op/proroghe" element={<OpProroghe />} /> 
            <Route path="/op/trasformazioni" element={<OpTrasformazione />} />
            <Route path="/op/infortuni" element={<OpInfortunio />} />
            <Route path="/op/contestazioni" element={<OpContestazioni />} />
            <Route path="/op/distacchi" element={<OpDistacco />} />
            <Route path="/op/distacchiesteri" element={<OpDistacchiEsteri />} />
            <Route path="/op/pratichegeneriche" element={<OpPraticheGeneriche />} />
            <Route path="/op/annullamenti" element={<OpAnnullamenti />} />

            <Route path="/op/assunzioni/detail" element={<DetAssunzione />} />
            <Route path="/op/cessazioni/detail" element={<DetCessazione />} />
            <Route path="/op/proroghe/detail" element={<DetProroghe />} /> 
            <Route path="/op/malattie/detail" element={<DetMalattia />} />
            <Route path="/op/cassaintegrazioni/detail" element={<DetCassaIntegrazione />} />
            <Route path="/op/trasformazioni/detail" element={<DetTrasformazione />} />
            <Route path="/op/infortuni/detail" element={<DetInfortunio />} />
            <Route path="/op/contestazioni/detail" element={<DetContestazioni />} />
            <Route path="/op/distacchi/detail" element={<DetDistacco />} />
            <Route path="/op/distacchiesteri/detail" element={<DetDistaccoEst />} />
            <Route path="/op/pratichegeneriche/detail" element={<DetPraticheGeneriche />} />
            <Route path="/op/annullamenti/detail" element={<DetAnnullamenti />} />

            <Route path="/op/amministrazione/listaaziende" element={<OpAmmListaAziende />} />
            <Route path="/op/amministrazione/avvisiparcella" element={<OpAmmAvvisiParcella />} />
            <Route path="/op/amministrazione/avvisiparcella/detail" element={<OpAmmAvvisiParcellaDet />} />
            <Route path="/op/amministrazione/fatture" element={<OpAmmFatture />} />
            <Route path="/op/amministrazione/pratichearchiviate" element={<OpAmmPraticheArchiviate />} />

            <Route path="/op/paghe/presenze" element={<OpPagPresenze />} />
            <Route path="/op/paghe/bustepaghe" element={<WorkinProgress />} />
            <Route path="/op/paghe/paghefatte" element={<OpPagheFatte />} />
            <Route path="/op/paghe/malattia" element={<OpPagMalattia />} />
            <Route path="/op/paghe/cassaintegrazione" element={<OpPagCassaIntegrazione />} />

            <Route path="/op/enti/durc" element={<OpEntiArc />} />
            <Route path="/op/enti/edilconnect" element={<OpEntiEdilConnect />} />
            <Route path="/op/enti/edilconnect/detail" element={<OpEntiEdilConnectDet />} />
            <Route path="/op/enti/noterett" element={<OpEntiNoteRett />} />

            <Route path="/op/pannellocontrollo" element={<OpPannelloControllo />} />
            <Route path="/op/amministrazione/opoperazioni" element={<OpOperazioni />} />
            <Route path="/op/amministrazione/fatturato" element={<OpAmmFatturato />} />
            <Route path="/op/situazionegenerale" element={<OpSituazioneGenerale />} />
            <Route path="/op/contabilita" element={<OpContabilita />} />
            <Route path="/op/news" element={<OpNews />} />

            <Route path="/op/dettaglioazienda" element={<DettaglioAzienda />} />

            <Route render={() => <div>Pagina non trovata</div>} />
          </Routes>
        </Box>
        <WhatsappButton /> 
        <Footer />
      </Box>
    </Router>
  );
};

export default App;
