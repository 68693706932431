import React from 'react';
import { CircularProgress, Typography, Container, Box } from '@mui/material';
import { Construction } from '@mui/icons-material';

const WorkInProgress = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const iconStyle = {
    fontSize: '5rem',
  };

  return (
    <Container style={containerStyle}>
      <Box textAlign="center">
        <Construction style={iconStyle} />
        <Typography variant="h4">Lavori in corso...</Typography>
        <Typography variant="body1">Questa parte dell'applicazione è ancora in fase di sviluppo.</Typography>
      </Box>
      <CircularProgress style={{ marginTop: '20px' }} />
    </Container>
  );
};

export default WorkInProgress;
