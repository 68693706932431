import React from 'react';
import PagPresenzeTab from './PagPresenzeTab';

function PagPresenze() {
  return (
    <div>
      <PagPresenzeTab />
    </div>
  );
}

export default PagPresenze;