import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import utils from "../../utils";
import { Container, Box, Typography, Divider, Drawer, Button, TextField, IconButton, Tooltip, Snackbar, Badge } from "@mui/material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Alert } from '@mui/material';

function Cli() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [ragioneSociale, setRagioneSociale] = useState("");
  const [newsCount, setNewsCount] = useState(0); // Stato per il conteggio delle notizie
  const [statoAvvisi, setStatoAvvisi] = useState('Pagato');

  useEffect(() => {
    loadData();
    fetchNewsCount(); // Funzione per ottenere il numero delle notizie
    fetchStatoAvvisi();
  }, []);

  const getDotColor = (stato) => {
    switch (stato) {
      case 'Pagato': return '#ffffff'; // White
      case 'Da Pagare': return '#ffeb3b'; // Yellow
      case 'In Scadenza': return '#ff9800'; // Orange
      case 'Scaduto': return '#f44336'; // Red
      default: return '#ccc'; // Gray
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          window.location.href = "/op";
          return;
        }
        if (success) {
          setRagioneSociale(me.ragione_sociale || ""); // Imposta la ragione sociale
          return; // stay on the page
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const fetchNewsCount = async () => {
    try {
      const res = await axios.get("/api/newsclientinuove", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200 && res.data.success) {
        setNewsCount(res.data.count); // Imposta il numero delle notizie
      }
    } catch (_) {
      console.error("Error fetching news count");
    }
  };

  const fetchStatoAvvisi = async () => {
    try {
      const res = await axios.get("/api/statoavvisicli", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200 && res.data.success) {
        setStatoAvvisi(res.data.stato);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleFeedbackSubmit = async () => {
    try {
      const res = await axios.post("/api/feedback", { feedback }, {
        headers: utils.getAuthHeaders(),
      });
      if (res.status === 200 && res.data.success) {
        setFeedback("");
        setDrawerOpen(false);
        setSnackbarMessage("Feedback inviato con successo!");
        setSnackbarSeverity("success");
      } else {
        console.error("Error submitting feedback", res.data.error);
        setSnackbarMessage("Errore nell'invio del feedback.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error submitting feedback", error);
      setSnackbarMessage("Errore nell'invio del feedback.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <style>
        {`
          .clienti-area {
            display: flex;
            justify-content: center;
          }

          .clienti-card {
            border: 1px solid #ccc;
            padding: 16px;
            margin: 16px;
            text-align: center;
            min-width: 200px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
            transition: transform 0.2s;
            text-decoration: none;
            color: black;
            border-radius: 10px;
            position: relative;
          }

          .clienti-card-amm {
            border: 1px solid #ccc;
            padding: 16px;
            margin: 16px;
            text-align: center;
            min-width: 200px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
            transition: transform 0.2s;
            text-decoration: none;
            color: black;
            background-color: ${getDotColor(statoAvvisi)};
            border-radius: 10px;
            position: relative;
          }

          .clienti-card:hover {
            transform: scale(1.05);
          }

          @media (max-width: 768px) {
            .clienti-card {
              min-width: auto;
              width: 80%;
              font-size: 16px;
            }

            .clienti-area {
              flex-direction: column;
              padding: 20px;
              margin-top: 2vh;
            }
          }

            .feedback-button {
              position: fixed;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1; /* Ensure it's above other elements */
          }

            .accounting-button {
              position: relative;
              left: 10px;
              bottom: 0px;
              z-index: 1;
              background-color: #ffeb3b;
              color: #000;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
              padding: 10px 20px;
              border-radius: 8px;
              font-size: 12px;

             .notification-badge {
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: red;
            color: white;
            border-radius: 50%;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: bold;
          }
        `}
      </style>

      <Tooltip title="Invia un feedback" arrow>
        <IconButton
          className="feedback-button"
          color="primary"
          onClick={handleDrawerToggle}
          aria-label="Feedback"
          sx={{ fontSize: 40 }} // Imposta la dimensione dell'icona
        >
          <FeedbackIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          role="presentation"
          sx={{ width: 250, padding: 2 }}
        >
          <Typography variant="h8" gutterBottom style={{ fontWeight: 'bold' }}>
            La tua opinione è importante
          </Typography>
          <Divider sx={{ my: 1 }} />
          <TextField
            multiline
            rows={17}
            variant="outlined"
            fullWidth
            label="Lasciaci qui il tuo feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
            placeholder={`Ci impegniamo costantemente per poter migliorare la vostra esperienza insieme a noi.\n
Il feedback è fondamentale per poter perfezionare il servizio ad ogni aggiornamento.\n
Facci sapere, proprio qui, cosa ti piacerebbe aggiungessimo per facilitare e velocizzare il tuo lavoro.\n
Grazie per aver scelto\nStudio Romeo.`}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFeedbackSubmit}
            fullWidth
          >
            Invia
          </Button>
        </Box>
      </Drawer>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={<Button color="inherit" onClick={handleCloseSnackbar}>Chiudi</Button>}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Typography variant="h4" gutterBottom align="center" style={{ marginTop: '30px' }}>
        Benvenuto/a nella tua Area Personale
      </Typography>

      {ragioneSociale && (
        <Typography
          variant="h6"
          align="center"
          style={{
            marginTop: '10px',
            fontStyle: 'italic',
            fontWeight: 'bold',
            color: '#616161',
            fontSize: '1.5rem' // Colore grigio scuro
          }}
        >
          {`${ragioneSociale}`}
        </Typography>
      )}

      <Divider sx={{ my: 1 }} />

      <Container maxWidth="md" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '55vh' }}>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" className="clienti-area">
          <Link to="/cli/contrattualistica" className="clienti-card">
            <h2>Contrattualistica</h2>
          </Link>
          <Link to="/cli/amministrazione" className="clienti-card-amm">
            <h2>Amministrazione</h2>
          </Link>
          <Link to="/cli/paghe" className="clienti-card">
            <h2>Paghe</h2>
          </Link>
          <Link to="/cli/enti" className="clienti-card">
            <h2>Enti</h2>
          </Link>
          <Link to="/cli/prenotasala" className="clienti-card">
            <h2>Sala Riunioni</h2>
          </Link>
          <Link to="/cli/prenotacba" className="clienti-card">
            <h2>Prenota CBA</h2>
          </Link>
          <Link to="/cli/previsionecosti" className="clienti-card">
            <h2>Previsione Costi</h2>
          </Link>
          <Link to="/cli/guida" className="clienti-card">
            {newsCount > 0 && (
              <Badge badgeContent={newsCount} color="error" sx={{ position: 'absolute', top: -10, right: -10 }}>
                <span style={{ visibility: 'hidden' }}>Guida Software</span>
              </Badge>
            )}
            <h2>Guida Software</h2>
          </Link>
          <Link to="/cli/domande" className="clienti-card">
            <h2>Domande</h2>
          </Link>
        </Box>
      </Container>
      {/* Button da usare in un secondo momento se serve per ora rimosso
      <Box>
        <Button
          className="accounting-button"
          variant="contained"
          onClick={() => window.location.href = '/cli/contabilita'}
          aria-label="Contabilità"
        >
          Contabilità
        </Button>
      </Box>
    */}
    </>
  );
}

export default Cli;