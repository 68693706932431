import React, { useState, useRef, useEffect } from 'react';
import { Typography, Card, CardContent, List, ListItem, Container, FormControl, InputLabel, Select, MenuItem, Grid, Button } from '@mui/material';
const scadenze = require('./scadenze_json');

const months = [
  { value: '01', label: 'Gennaio' },
  { value: '02', label: 'Febbraio' },
  { value: '03', label: 'Marzo' },
  { value: '04', label: 'Aprile' },
  { value: '05', label: 'Maggio' },
  { value: '06', label: 'Giugno' },
  { value: '07', label: 'Luglio' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Settembre' },
  { value: '10', label: 'Ottobre' },
  { value: '11', label: 'Novembre' },
  { value: '12', label: 'Dicembre' },
];

const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
const weekdays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

const EventComponent = ({ htmlString }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

const formatToItalianDate = (dateString) => {
  const [year, month, day] = dateString.split('T')[0].split('-');
  const monthName = months.find((m) => m.value === month)?.label || '';
  const formattedDate = `${parseInt(day, 10)} ${monthName} ${year}`;
  return formattedDate;
};

const Scadenze = () => {
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [selectedDate, setSelectedDate] = useState({ day: null, month: null });
  const cardRef = useRef(null);

  useEffect(() => {
    if (selectedDate !== null && cardRef.current !== null) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedDate]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setSelectedDate({ day: null, month: null });
  };

  const handleDayClick = (day, month) => {
    setSelectedDate({ day, month });
  };

  return (
    <Container component="main" maxWidth="xl">

      <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: "20px", textAlign: "center" }}>
        Scadenze
      </Typography>

      <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="monthSelector">Seleziona un mese</InputLabel>
            <Select
              label="Seleziona un mese"
              id="monthSelector"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <MenuItem value="all">Tutti i mesi</MenuItem>
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>

          {selectedMonth === 'all' && (
            <Grid container spacing={1}>
              {months.map((month) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={month.value}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>{month.label}</Typography>
                      <Grid container spacing={1}>
                        {[...Array(daysInMonth(new Date().getFullYear(), parseInt(month.value, 10) - 1)).keys()].map(day => (
                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} key={day}>
                            <Button
                              variant="contained"
                              disabled={!scadenze.items.some(dayObj => parseInt(dayObj.expirationDate.slice(8, 10), 10) === day + 1 && parseInt(dayObj.expirationDate.slice(5, 7), 10) === parseInt(month.value, 10))}
                              onClick={() => handleDayClick(day + 1, month.value)}
                              style={{ width: 'auto', height: '30px', fontSize: '8px'}}
                              >
                              {`${day + 1} ${weekdays[new Date(new Date().getFullYear(), parseInt(month.value, 10) - 1, day + 1).getDay()]}`}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          
          {selectedMonth !== 'all' && (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>{months.find((m) => m.value === selectedMonth)?.label}</Typography>
                <Grid container spacing={1}>
                  {[...Array(daysInMonth(new Date().getFullYear(), parseInt(selectedMonth, 10) - 1)).keys()].map(day => (
                    <Grid item xs={3} sm={3} md={2} lg={1} key={day}>
                      <Button
                        variant="contained"
                        disabled={!scadenze.items.some(dayObj => parseInt(dayObj.expirationDate.slice(8, 10), 10) === day + 1 && parseInt(dayObj.expirationDate.slice(5, 7), 10) === parseInt(selectedMonth, 10))}
                        onClick={() => handleDayClick(day + 1, selectedMonth)}
                        style={{ width: '90%' }}
                        >
                        {`${day + 1} ${weekdays[new Date(new Date().getFullYear(), parseInt(selectedMonth, 10) - 1, day + 1).getDay()]}`}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      {scadenze.items.map((dayObj) => {
        const formattedDate = formatToItalianDate(dayObj.expirationDate);
        const m = dayObj.expirationDate.slice(5, 7);
        if (((selectedMonth === 'all' && (selectedDate.month === m || selectedDate.month === null)) || selectedMonth === m) && (selectedDate.day === null || selectedDate.day === parseInt(dayObj.expirationDate.slice(8, 10), 10))) {
          return (
            <Card ref={selectedDate.day === parseInt(dayObj.expirationDate.slice(8, 10), 10) ? cardRef : null} key={dayObj.expirationDate} variant="outlined" lg={{ mb: 3 }}>
              <CardContent style={{ paddingBottom: '10px' }}>
                <Typography variant="h5" sx={{ mt: 1, ml: 1 }}>{formattedDate}</Typography>
                <List>
                  {dayObj.dto.map((event, index) => (
                    <ListItem key={index}>
                      <EventComponent htmlString={event.title} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          );
        }

        return null;
      })}
    </Container>
  );
};

export default Scadenze;