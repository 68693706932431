import React, { useEffect, useState } from "react";
import { Button, Box, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import CliContrattTab from "./CliContrattTab";

const pratiche = [
  "assunzione",
  "cessazione",
  "proroga",
  "trasformazione",
  "infortunio",
  "contestazione",
  "distacco",
  "distacco estero",
  "vardatori",
];

const buttonColors = [
  "#333333",
  "#333333",
  "#333333",
  "#333333",
  "#333333",
  "#333333",
  "#333333",
  "#333333",
  "#333333",
];

const buttonStyles = {
  width: "100%",
  marginBottom: "10px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.2s ease",
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  [theme.breakpoints.up("lg")]: {
    marginLeft: "10px",
    margin: "10px",
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const DashboardContainer = styled(Box)(() => ({
  width: "250px",
  maxWidth: "100%",
  padding: "20px",
  borderRadius: "10px",
  background: "linear-gradient(to bottom, #1e1e1e, #353535)",
  boxShadow: "5px 5px 10px 0px #888888",
  color: "white",
  margin: "10px",
}));

const CliContratt = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          window.location.href = "/op";
          return;
        }
        if (success) {
          return; // rimani sulla pagina
        }
      }
    } catch (_) {}
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  return (
    <Container>
      <DashboardContainer>
        <Typography textAlign='center' variant="h6" sx={{ mb: 1 }}>Nuova Pratica</Typography>
        {buttonColors.map((buttonColor, index) => (
          <Link
            key={index}
            to={`/cli/contrattualistica/${pratiche[index].replace(' ', '')}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                backgroundColor: buttonColor,
                transform:
                  hoveredIndex === index ? "scale(1.05)" : "scale(1)",
                  filter: hoveredIndex === index ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              {pratiche[index]}
            </Button>
          </Link>
        ))}
      </DashboardContainer>
        <CliContrattTab />
    </Container>
  );
};

export default CliContratt;
