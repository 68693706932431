import React, { useState, useEffect } from 'react';
import {
  Container, Typography, FormControl, TextField, Autocomplete, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Button, Paper, Stack, CircularProgress
} from '@mui/material';
import {
  CheckCircleOutline,
  HighlightOffOutlined,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";


const AvvisiParcelle = () => {
  const [aziendaSelezionata, setAziendaSelezionata] = useState(null);
  const [avvisi, setAvvisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [aziende, setAziende] = useState([]);

  useEffect(() => {
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadData = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/avvisiparcella?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAvvisi(res.data.avvisi);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleChange = (nuovaAzienda) => {
    setAziendaSelezionata(nuovaAzienda);
    loadData(nuovaAzienda);
  };

  const handleCreaAvviso = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/createavvisoparcella`, { id: aziendaSelezionata }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, id } = res.data;
      if (success) {
        return window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + id;
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleRowClick = (avviso) => {
    window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + avviso.id;
  };

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{ mt: 3 }}>
        Avvisi di Parcella
      </Typography>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          id="azienda"
          value={aziendaSelezionata}
          onChange={(event, newValue) => {
            handleChange(newValue)
          }}
          options={aziende.map((az) => az.id)}
          getOptionLabel={(id) => {
            const a = aziende.filter(az => az.id == id)[0];
            return `${a.nome} (${a.id})`;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Azienda"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </FormControl>

      {loading ? <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack> : <div>
        {aziendaSelezionata && (
          <Button variant="contained" color="primary" onClick={() => handleCreaAvviso()} style={{ marginBottom: '16px' }}>
            Crea Avviso
          </Button>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Data Documento</TableCell>
                <TableCell>Data Pagamento</TableCell>
                <TableCell>Totale da Pagare</TableCell>
                <TableCell align="center">Confermato</TableCell>
                <TableCell align="center">Pagato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avvisi.map((avviso) => (
                <TableRow key={avviso.id} onClick={() => handleRowClick(avviso)} style={{ cursor: 'pointer' }}>
                  <TableCell>{avviso.id}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_documento)}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_pagamento)}</TableCell>
                  <TableCell>{(Number(avviso.tot_a_pagare) / 100).toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {avviso.confermato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {avviso.pagato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {statusMessage && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}
      </div>}
    </Container>
  );
};

export default AvvisiParcelle;
