import React from "react";
import { Typography, List, ListItem, ListItemText, ListItemIcon, Paper, Box, Container, Grid } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";

// Importa i moduli dello stage dalla cartella "moduliStage"
const moduleFilesStage = require.context('../../modulistage', false, /\.(pdf|doc|docx)$/);
const moduliStage = moduleFilesStage.keys().map((fileName, index) => ({
  id: index + 1,
  nome: fileName.replace(/^.*[\\/]/, ''),
  file: fileName,
}));

// Importa i moduli dalla cartella "moduli"
const moduleFiles = require.context('../../moduli', false, /\.(pdf|doc|docx)$/);
const moduli = moduleFiles.keys().map((fileName, index) => ({
  id: index + 1,
  nome: fileName.replace(/^.*[\\/]/, ''),
  file: fileName,
}));

const getFileIcon = (file) => {
  const fileExtension = file.split('.').pop().toLowerCase();

  if (fileExtension === "pdf") {
    return <PictureAsPdfIcon style={{ color: "red" }} />;
  } else if (fileExtension === "doc" || fileExtension === "docx") {
    return <DescriptionIcon style={{ color: "blue" }} />;
  } else {
    return <GetAppIcon />;
  }
};

const Moduli = () => {
  const handleDownload = (file, webpack) => {
    const downloadUrl = webpack(file);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = file.replace(/^.*[\\/]/, '');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ borderRadius: "8px", padding: "16px", my: 3 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Moduli Stage
            </Typography>
            <Box textAlign="left">
              <List>
                {moduliStage.map((modulo) => (
                  <div key={modulo.id}>
                    <ListItem button onClick={() => handleDownload(modulo.file, moduleFilesStage)}>
                      <ListItemIcon>
                        {getFileIcon(modulo.file)}
                      </ListItemIcon>
                      <ListItemText primary={modulo.nome} />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ borderRadius: "8px", padding: "16px", my: 3 }}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Altri Moduli
            </Typography>
            <Box textAlign="left">
              <List>
                {moduli.map((modulo) => (
                  <div key={modulo.id}>
                    <ListItem button onClick={() => handleDownload(modulo.file, moduleFiles)}>
                      <ListItemIcon>
                        {getFileIcon(modulo.file)}
                      </ListItemIcon>
                      <ListItemText primary={modulo.nome} />
                    </ListItem>
                  </div>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Moduli;
