import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import OpAmmTab from './OpAmmTab';

const OpAmm = () => {
  const [username, setUsername] = useState("");
  const [hoveredLink, setHoveredLink] = useState(null);

  const avatarUrl = "url_dell_avatar";

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && !me.op) {
          window.location.href = "/cli";
          return;
        }
        if (success) {
          setUsername(`${me.nome.charAt(0).toUpperCase()}${me.nome.substring(1).toLowerCase()} ${me.cognome.charAt(0).toUpperCase()}.`);
          return;
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const links = [
    {
      to: "/op/amministrazione/listaaziende",
      label: "Lista Aziende",
      color: "#4caf50",
      hoverState: "ListaAziende",
    },
    {
      to: "/op/amministrazione/avvisiparcella",
      label: "Avvisi di Parcella",
      color: "#ff5423",
      hoverState: "Avvisi",
    },
    {
      to: "/op/amministrazione/fatture",
      label: "Fatture",
      color: "#DAA520",
      hoverState: "Fatture",
    },
    {
      to: "/op/amministrazione/pratichearchiviate",
      label: "Pratiche Archiviate",
      color: "#00afff",
      hoverState: "PraticheArchiviate",
    },
  ];

  const handleMouseEnter = (state) => {
    setHoveredLink(state);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      spacing={0}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        lg={2}
        xl={2}
        style={{
          background: "linear-gradient(to bottom, #1e1e1e, #353535)",
          padding: "20px",
          borderRadius: "2vh",
          height: "auto", // Adjusted to fit content
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
          margin: "20px",
        }}
      >
        <Grid container alignItems="center" style={{ paddingBottom: "20px" }}>
          <Grid item>
            <Avatar
              alt={username}
              src={avatarUrl}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="white">
              {username}
            </Typography>
          </Grid>
        </Grid>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          {links.map(({ to, label, color, hoverState }) => (
            <Link
              key={to}
              to={to}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "12px 0", // Increased padding for better touch targets
                borderRadius: "4px",
                color: "white",
                transition: "transform 0.2s, filter 0.2s, box-shadow 0.2s",
                boxShadow: "10px 10px 10px rgba(10, 10, 0, 0.5)",
                marginBottom: "10px",
                backgroundColor: hoveredLink === hoverState ? color : "#333",
                transform: hoveredLink === hoverState ? "scale(1.05)" : "scale(1)",
                filter: hoveredLink === hoverState ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => handleMouseEnter(hoverState)}
              onMouseLeave={handleMouseLeave}
            >
              {label}
            </Link>
          ))}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{
          margin: "20px",
        }}
      >
        {/* Aggiungi il componente <OpAmmTab/> qui */}
        
      </Grid>
    </Grid>
  );
};

export default OpAmm;
