import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Stack,
  Container,
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";

const OpEntiNoteRett = () => {
  const [requests, setRequests] = useState([]);
  const [requestTotal, setRequestTotal] = useState(0);
  const [requestPage, setRequestPage] = useState(0);
  const [requestLoading, setRequestLoading] = useState(true);
  const [statusMessageRequests, setStatusMessageRequests] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [descrizione, setDescrizione] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(0);
  const [risposta, setRisposta] = useState("");
  const [openPricePopup, setOpenPricePopup] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showPrezzo, setShowPrezzo] = useState(false);

  const rowsPerPage = 8;

  useEffect(() => {
    loadRequests(0);
  }, []);

  const loadRequests = async (p) => {
    try {
      const res = await axios.get(`/api/noteinpsarchivio?page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setRequests(res.data.tickets.map(t => { return { ...t, prezzo: t.prezzo && (Number(t.prezzo) / 100).toFixed(2) } }));
        setShowPrezzo(res.data.tickets.length ? typeof res.data.tickets[0].prezzo == 'number' : false);
        setRequestTotal(res.data.total);
      } else {
        setStatusMessageRequests(error);
      }
    } catch (error) {
      setStatusMessageRequests(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setRequestLoading(false);
  };

  const handleRequestPage = (forward) => {
    if (requestPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (requestPage + 1) >= requestTotal && forward) {
      return;
    }
    const newPage = requestPage + (forward ? 1 : -1);
    setRequestPage(newPage);
    setRequestLoading(true);
    loadRequests(newPage);
  };

  const CellStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  };

  const centerText = {
    textAlign: "center",
  };

  const dataRowSX = {
    display: "table-row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  };

  const updateTicketPrice = (id, price) => {
    const updatedTickets = requests.map(ticket => {
      if (ticket.id === id) {
        return { ...ticket, prezzo: price };
      }
      return ticket;
    });
    setRequests(updatedTickets);
  };

  const saveTicketPrice = async (id) => {
    setStatusMessageRequests("");
    const ticket = requests.find(ticket => ticket.id === id);
    const prezzoInt = Math.round(Number(ticket.prezzo.toString().replace(',', '.')) * 100);
    if (!prezzoInt) {
      setStatusMessageRequests("Valore non valido per il prezzo: " + ticket.prezzo);
      return;
    }
    setRequestLoading(true);
    try {
      const res = await axios.post(`/api/updateprezzoenti`, {
        id: ticket.id,
        tipo: 'note_inps',
        prezzo: prezzoInt
      }, {
        headers: utils.getAuthHeaders(),
      });
      if (res.data.success) {
        setOpenPricePopup(false);
        setSelectedRowId(null);
      } else {
        setStatusMessageRequests("Errore nel salvataggio del prezzo");
      }
    } catch (error) {
      setStatusMessageRequests("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setRequestLoading(false);
  };

  const renderTableRow = (row) => (
    <TableRow key={row.id} sx={dataRowSX}>
      <TableCell style={{ ...centerText }}>{row.id}</TableCell>
      <TableCell style={{ ...centerText }}>{utils.revDate(row.dataRichiesta)}</TableCell>
      <TableCell style={{ ...centerText }}>{row.nome}</TableCell>
      <TableCell style={{ ...centerText }}>
        <Button variant="contained" color="primary" onClick={() => setDescrizione(row.descrizione)}>
          Visualizza
        </Button>
      </TableCell>
      <TableCell style={{ ...centerText }}>
        <Button variant="contained" color="primary" onClick={() => downloadDoc(row.docInps)}>
          Apri
        </Button>
      </TableCell>
      <TableCell style={{ ...centerText }}>
        <Button variant="contained" color={row.risposta ? "warning" : "primary"} onClick={() => handleOpenDialog(row.id, row.risposta)}>
          {row.risposta ? "Modifica" : "Rispondi"}
        </Button>
      </TableCell>
      {showPrezzo && <TableCell style={centerText}>
        <Typography
          variant="body1"
          style={{ cursor: 'pointer' }}
          onClick={() => { setSelectedRowId(row.id); setOpenPricePopup(true); }}
        >
          {row.prezzo !== undefined ? row.prezzo : 'Prezzo'}
        </Typography>
      </TableCell>}

      <Dialog
        open={openPricePopup && selectedRowId === row.id}
        onClose={() => {
          setOpenPricePopup(false);
          setSelectedRowId(null);
        }}
      >
        <DialogTitle>Prezzo</DialogTitle>
        <DialogContent>
          <TextField
            value={row.prezzo || ""}
            onChange={(e) => updateTicketPrice(row.id, e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenPricePopup(false);
              setSelectedRowId(null);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => saveTicketPrice(row.id)}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );

  const handleOpenDialog = (id, risp) => {
    setSelectedTicket(id);
    setRisposta(risp)
    setDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setSelectedTicket(0);
    setRisposta("");
    setDialogOpen(false);
  }

  // Funzione per scaricare un documento dato l'ID
  const downloadDoc = async (docId) => {
    setStatusMessageRequests("");
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setStatusMessageRequests("File non trovato.");
    }
  };

  const handleSubmit = async () => {
    setDialogOpen(false);
    setStatusMessageRequests("");
    setRequestLoading(true);
    try {
      const res = await axios.post(`/api/aggiornanoteinps`,
        { id: selectedTicket, risposta },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      setRisposta("");
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageRequests(error);
      }
    } catch (error) {
      setStatusMessageRequests(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setRequestLoading(false);
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
        Richieste Note di Rettifica INPS
      </Typography>

      {requestLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box sx={{ overflowX: "auto" }}>
          <Paper sx={{ borderRadius: "10px", overflow: "hidden" }} variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={dataRowSX}>
                    <TableCell style={CellStyle}>ID Richiesa</TableCell>
                    <TableCell style={CellStyle}>Data Richiesta</TableCell>
                    <TableCell style={CellStyle}>Ragione Sociale</TableCell>
                    <TableCell style={CellStyle}>Descrizione</TableCell>
                    <TableCell style={CellStyle}>Documenti</TableCell>
                    <TableCell style={CellStyle}>Rispondi</TableCell>
                    {showPrezzo && <TableCell style={CellStyle}>Prezzo</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>{requests.map(renderTableRow)}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <Typography
              fontSize="1.1rem"
              sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
            >
              {`${requestPage * rowsPerPage + 1} - ${requestPage * rowsPerPage + requests.length} di ${requestTotal}`}
            </Typography>
            <IconButton
              onClick={() => handleRequestPage(false)}
              disabled={requestPage === 0}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => handleRequestPage(true)}
              disabled={rowsPerPage * (requestPage + 1) >= requestTotal}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          {(statusMessageRequests) && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                my: 1,
                mx: 2,
                flexGrow: 1,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
              }}
              role="alert"
            >
              {statusMessageRequests}
            </Paper>
          )}
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Inserisci Risposta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              label="Risposta"
              variant="outlined"
              fullWidth
              value={risposta}
              onChange={(e) => setRisposta(e.target.value)}
              multiline
              rows={6}
              margin="normal"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Annulla
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Invia
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!descrizione} onClose={() => setDescrizione(null)}>
        <DialogTitle>Descrizione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {descrizione}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDescrizione(null)} color="primary" autoFocus>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default OpEntiNoteRett;
