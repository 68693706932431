import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Stack,
  Container,
  CircularProgress,
  ButtonBase,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  GetApp,
  CheckCircle,
  Cancel
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";

const CliEntiTab = ({ statusMessage, isLoading }) => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const rowsPerPage = 8;

  useEffect(() => {
    loadTickets(0);
  }, []);

  const loadTickets = async (p) => {
    try {
      const res = await axios.get(`/api/durcsforuser?page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleDownload = async (dataRichiesta) => {
    try {
      const response = await axios.get(`/api/scaricadocdurc?d=${dataRichiesta}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const CellStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  };

  const centerText = {
    textAlign: "center",
  };

  const dataRowSX = {
    display: "table-row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  };

  const renderTableRow = (row) => (
    <ButtonBase key={row.id} sx={dataRowSX} component={TableRow}>
      <TableCell style={centerText}>{utils.revDate(row.dataRichiesta)}</TableCell>
      <TableCell style={centerText}>{utils.revDate(row.dataCreazione)}</TableCell>
      <TableCell style={centerText}>{utils.revDate(row.dataScadenza)}</TableCell>
      <TableCell style={centerText}>{row.completato ? "Completato" : "In lavorazione"}</TableCell>
      {row.completato ? <TableCell style={centerText}>
        {row.concesso ? (
          <CheckCircle sx={{ color: "green" }} />
        ) : (
          <Cancel sx={{ color: "red" }} />
        )}
      </TableCell> : <TableCell style={centerText}></TableCell>}
      <TableCell style={centerText}>
        {row.completato && row.concesso && (
          <Button
            onClick={() => handleDownload(row.dataRichiesta)}
            variant="contained"
            startIcon={<GetApp />}
            sx={{ color: 'white', backgroundColor: 'green' }}
          >
            Scarica
          </Button>
        )}
      </TableCell>
    </ButtonBase>
  );

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
        Richieste Durc
      </Typography>

      {ticketLoading || isLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box sx={{ overflowX: "auto" }}>
          <Paper sx={{ borderRadius: "10px", overflow: "hidden" }} variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={CellStyle}>Data Richiesta</TableCell>
                    <TableCell style={CellStyle}>Data Creazione</TableCell>
                    <TableCell style={CellStyle}>Data Scadenza</TableCell>
                    <TableCell style={CellStyle}>Stato Pratica</TableCell>
                    <TableCell style={CellStyle}>Concesso</TableCell>
                    <TableCell style={CellStyle}>Documenti</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tickets.map(renderTableRow)}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <Typography
              fontSize="1.1rem"
              sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
            >
              {`${ticketPage * rowsPerPage + 1} - ${ticketPage * rowsPerPage + tickets.length} di ${ticketTotal}`}
            </Typography>
            <IconButton
              onClick={() => handleTicketPage(false)}
              disabled={ticketPage === 0}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => handleTicketPage(true)}
              disabled={rowsPerPage * (ticketPage + 1) >= ticketTotal}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          {(statusMessageTickets || statusMessage) && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                my: 1,
                mx: 2,
                flexGrow: 1,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
              }}
              role="alert"
            >
              {statusMessageTickets || statusMessage}
            </Paper>
          )}
        </Box>
      )}
    </Container>
  );
};

export default CliEntiTab;