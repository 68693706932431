import React from 'react';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import OpContrattTab from './OpContrattTab';

const PraticheArchiviate = () => {

  return (
    <Container>
      <Box>
        <Typography variant="h5" margin={3} align="center" fontWeight="bold" gutterBottom>
          Pratiche Completate
        </Typography>
        <OpContrattTab dafare={false} />
      </Box>
    </Container>
  );
};

export default PraticheArchiviate;
