import React, { useState, useEffect } from 'react';
import {
  FormControl, Select, MenuItem, InputLabel, Button, Typography,
  Box, Container, Grid, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, CircularProgress, Snackbar, Alert, Paper, Tooltip
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import utils from '../../utils';

const CliAmmFatture = () => {
  const now = new Date();
  const [anno, setAnno] = useState(now.getFullYear());
  const [fatture, setFatture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const anni = ['2020'];
  while (now.getFullYear() > anni[anni.length - 1]) {
    anni.push((Number(anni[anni.length - 1]) + 1).toString());
  }

  useEffect(() => {
    loadData(now.getFullYear());
  }, [anno]);

  const loadData = async (year) => {
    try {
      const res = await axios.get(`/api/fatture?anno=${year}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setFatture(res.data.fatture);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const downloadDoc = async (docId) => {
    try {
      const response = await axios.get(`/api/scaricadoccliente?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setError("File non trovato.");
    }
  };

  return (
    <Container maxWidth="xxl">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Gestione Fatture
        </Typography>
        <Box sx={{ marginBottom: 3 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Anno</InputLabel>
                <Select
                  value={anno}
                  onChange={(e) => setAnno(e.target.value)}
                  label="Anno"
                  sx={{ height: '50px' }} // Uniforma l'altezza
                >
                  {anni.map((anno, index) => (
                    <MenuItem key={index} value={anno}>
                      {anno}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {loading && <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />}
        {error && (
          <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        <TableContainer sx={{ marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">ID Avviso di Parcella</TableCell>
                <TableCell align="center">F24</TableCell>
                <TableCell align="center">Scarica Fattura</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fatture.map((fattura, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell align="center">{fattura.date}</TableCell>
                    <TableCell align="center">
                      {fattura.avviso_id ? (
                        <Button
                          variant="text"
                          disabled={true}
                        >
                          {fattura.avviso_id}
                        </Button>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="F24 cedolino">
                        <span>
                          <DownloadIcon
                            color={fattura.doc_f24 ? 'success' : 'disabled'}
                            sx={{ cursor: 'pointer', marginRight: 2 }}
                            onClick={() => fattura.doc_f24 && downloadDoc(fattura.doc_f24)}
                          />
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Scarica documento">
                        <span>
                          <DownloadIcon
                            color={fattura.doc ? 'success' : 'disabled'}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => fattura.doc && downloadDoc(fattura.doc)}
                          />
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default CliAmmFatture;
