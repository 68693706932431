import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Paper,
  CircularProgress,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import JSZip from 'jszip';
import axios from "axios";
import utils from "../../utils";

const ContrPraticheGeneriche = ({ op }) => {
  const [formValues, setFormValues] = useState({
    nome: "",
    cognome: "",
    nomePratica: "",
    descrizionePratica: "",
    files: null,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [aziende, setAziende] = useState([]);
  const [operatori, setOperatori] = useState([{ id: 0, nome: 'Caricamento...' }]);

  // Effetto per il caricamento dei dati iniziali
  useEffect(() => {
    loadData();
    if (op) {
      loadAziendeEOp();
    }
  }, []);

  // Funzione per il caricamento dei dati iniziali
  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && op && !me.op) {
          window.location.href = "/cli";
          return;
        } if (success) {
          setLoading(false);
          return; // Rimani sulla pagina
        } else if (error) {
          setStatusMessage(error);
        }
      }
    } catch (error) {
      setStatusMessage(error);
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        setOperatori(res.data.operatori);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    if (files.length == 1) {
      // per un file non genera lo zip, solo se sono piu di uno
      setFormValues({ ...formValues, files: { name: files[0].name, file: files[0] } });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setFormValues({ ...formValues, files: { name: 'documenti.zip', zippedBlob } });
    });
  }

  const handleSubmit = async (e) => {
    setStatusMessage("");
    e.preventDefault();

    if (!formValues.nomePratica || !formValues.descrizionePratica) {
      setStatusMessage("Inserire il nome e la descrizione della pratica");
      return;
    }

    const json = {
      tipo: "generica",
      nome: formValues.nome,
      cognome: formValues.cognome,
      nome_pratica: formValues.nomePratica,
      descrizione_pratica: formValues.descrizionePratica,
      docs: formValues.files ? formValues.files.name : null,
      azienda: formValues.azienda,
      in_carico: formValues.inCarico,
    }

    const payload = new FormData();

    if (json.docs) {
      payload.append("docs", formValues.files.file || formValues.files.zippedBlob);
    }

    payload.append("data", JSON.stringify(json));

    try {
      setLoading(true);
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setLoading(false);
      if (success) {
        if (op) {
          window.location.href = "/success?backto=%2Fop%2Fcontrattualistica";
        } else {
          window.location.href = "/success";
        }
      } else {
        setStatusMessage(error);
        return;
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 413) {
        setStatusMessage(
          "I files sono troppo grandi. Il limite massimo è di 100 MB."
        );
      } else {
        setStatusMessage(
          "Si è verificato un errore imprevisto sul nostro server."
        );
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mt={3}>
        Pratica Generica
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <form className="pratica-generica-form" onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap={2}
            borderRadius={5}
            border="1px solid #ccc"
            padding={3}
            marginX="auto"
            gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
          >
            <TextField
              label="Nome"
              type="text"
              name="nome"
              value={formValues.nome}
              onChange={handleInputChange}
            />
            <TextField
              label="Cognome"
              type="text"
              name="cognome"
              value={formValues.cognome}
              onChange={handleInputChange}
            />
            <TextField
              label="Nome Pratica"
              type="text"
              name="nomePratica"
              value={formValues.nomePratica}
              onChange={handleInputChange}
              required
              sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}
            />
            <TextField
              label="Descrizione Pratica"
              type="text"
              name="descrizionePratica"
              value={formValues.descrizionePratica}
              onChange={handleInputChange}
              required
              multiline
              rows={5}
              sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}
            />
          </Box>
          <Box
            display="grid"
            gap={1}
            borderRadius={5}
            border="1px solid #ccc"
            padding={3}
            marginX="auto"
            marginBottom={2}
            gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
            mt={2}
          >
            <label htmlFor="files">Seleziona i files (facoltativo)</label>
            <input
              type="file"
              name="files"
              onChange={handleMultiFileChange}
              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
              multiple
              style={{ gridColumn: { xs: "span 1", md: "span 2" } }}
            />
          </Box>
          <Box
            display="grid"
            gap={1}
            borderRadius={5}
            border="1px solid #ccc"
            padding={3}
            marginX="auto"
            gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
          >
            {op ? (<h2>Incarica a</h2>) : null}
            {op ? (<Typography></Typography>) : null}
            {op ?
              (
                <FormControl>
                    <Autocomplete
                    id="azienda"
                    value={formValues.azienda}
                    onChange={(event, newValue) => {
                      setFormValues({
                        ...formValues,
                        azienda: newValue,
                      });
                    }}
                    options={aziende.map((az) => az.id)}
                    getOptionLabel={(id) => {
                      const a = aziende.filter(az => az.id == id)[0];
                      return `${a.nome} (${a.id})`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Azienda"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              ) : null
            }
            {op ?
              (
                <FormControl>
                  <InputLabel id="select-op-label">Operatore</InputLabel>
                  <Select
                    labelId="inCarico-label"
                    id="inCarico"
                    name="inCarico"
                    value={formValues.inCarico}
                    onChange={handleSelectChange}
                    required
                    label="Operatore"
                  >
                    {operatori.map((ope) => (
                      <MenuItem key={ope.id} value={ope.id}>{ope.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null
            }
          </Box>
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection="column"
            px={2}
          >
            {statusMessage && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessage}
              </Paper>
            )}
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              px={2}
            >
              {loading ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <Button type="submit" variant="contained" sx={{ mt: 2, px: 8 }}>
                  Invia
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ContrPraticheGeneriche;
