import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  ButtonBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // Importa l'icona desiderata
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";

const dataRowSX = {
  display: "table-row",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

const PRATICHE = [
  "assunzioni",
  "cessazioni",
  "proroghe",
  "trasformazioni",
  "malattie",
  "infortuni",
  "contestazioni",
  "distacchi",
  "distacchiesteri",
  "cassaintegrazioni",
  "annullamenti",
  "pratichegeneriche"
];

const PRATICHE_EVIDENZIATE = [
  "as",
  "ce",
  "pr",
  "tr",
  "di",
  "de",
];

const getInizioId = (tipo) => {
  if (tipo == 'distacchiesteri') {
    return 'de';
  }
  if (tipo == 'pratichegeneriche') {
    return 'ge';
  }
  return tipo.substring(0, 2);
}

const evidenzia = (row) => {
  return PRATICHE_EVIDENZIATE.includes(row.id.toLowerCase().substring(0, 2)) &&
    row.data_effetto <= new Date().toISOString().substring(0, 10);
}

const OpContrattTab = (props) => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(props.dafare ? 6 : 15);

  useEffect(() => {
    loadTickets(0, rowsPerPage);
  }, []);

  const loadTickets = async (p, size) => {
    try {
      const res = await axios.get(`/api/tickets${props.dafare ? 'dafare' : 'fatti'}?page=${p}&size=${size}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setTicketPage(0); // Resetta alla prima pagina
    setTicketLoading(true);
    loadTickets(0, newRowsPerPage); // Ricarica i ticket con la nuova impostazione di righe per pagina
  };

  return (
    <Box>
      {ticketLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (<div>
        <Box sx={{ margin: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#333" }}>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "10%" }}>
                    ID
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "20%" }}>
                    Azienda
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "20%" }}>
                    Lavoratore
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "15%" }}>
                    Data Richiesta
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "15%" }}>
                    Data Effetto
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "10%" }}>
                    Incaricato
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", color: "white", width: "10%" }}>
                    Urgente
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.map((row) => (
                  <ButtonBase
                    component={Link}
                    to={`/op/${PRATICHE.filter(p => getInizioId(p) == row.id.toLowerCase().substring(0, 2))[0]}/detail?id=${row.id}`}
                    key={row.id}
                    sx={{ ...dataRowSX, backgroundColor: evidenzia(row) ? 'rgba(235, 50, 38, 0.5)' : 'inherit' }}
                  >
                    <TableCell align="center" sx={{ width: "10%" }}>
                      {row.id}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "20%" }}>
                      {row.azienda}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "20%" }}>
                      {row.dip_cognome + ' ' + row.dip_nome}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "15%" }}>
                      {utils.revDate(row.timestamp.substring(0, 10))}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "15%" }}>
                      {utils.revDate(row.data_effetto)}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "10%" }}>
                      {row.incaricato}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "10%" }}>
                      {row.urgente ? (
                        <ErrorOutlineIcon style={{ color: "red" }} />
                      ) : null}
                    </TableCell>
                  </ButtonBase>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {tickets.length == 0 && <Typography textAlign='center' sx={{ mt: 3 }}>{props.dafare ? 'Tutte le pratiche sono state completate :)' : 'Nessuna pratica completata'}</Typography>}
        </Box>
        <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
          <FormControl variant="outlined" sx={{ minWidth: 70, height: 40, marginLeft: 2}}>
            <Select
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              sx={{ fontSize: '0.75rem', height: 36, '.MuiSelect-select': { padding: '8px' }, '.MuiOutlinedInput-notchedOutline': { borderRadius: '4px' } }}
            >
              {[1, 3, 5, 6, 8, 10, 15, 20].map((rows) => (
                <MenuItem key={rows} value={rows} sx={{ fontSize: '0.75rem' }}>
                  {rows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <Typography
            fontSize="1.1rem"
            sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
          >
            {`${ticketPage * rowsPerPage + 1} - ${ticketPage * rowsPerPage + tickets.length
              } di ${ticketTotal}`}
          </Typography>
          <IconButton
            onClick={() => handleTicketPage(false)}
            disabled={ticketPage === 0}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={() => handleTicketPage(true)}
            disabled={rowsPerPage * (ticketPage + 1) >= ticketTotal}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
        {statusMessageTickets && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              my: 1,
              mx: 2,
              flexGrow: 1,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessageTickets}
          </Paper>
        )}
      </div>)}
    </Box>
  );
};

export default OpContrattTab;
