// ServiceCard.js
import React from "react";
import "./ServiceCard.css";

const services = [
  {
    title: "Contrattualistica",
    description: "Assunzioni, Cessazioni, Proroghe",
    imageSrc: "/img/service1.jpg",
  },
  {
    title: "Amministrazione",
    description: "Avvisi di Parcella, Fatture, Scadenze",
    imageSrc: "/img/service2.jpg",
  },
  {
    title: "Buste Paga",
    description: "Presenze, Ore, Paghe",
    imageSrc: "/img/service4.jpg",
  },
  {
    title: "Enti",
    description: "Durc, Inps, Inail, Cassa Edile",
    imageSrc: "/img/service3.jpg",
  },
];

const ServiceCard = () => {
  return (
    <div className="service-cards-container">
      <div className="title-container">
        <h1>Servizi</h1>
      </div>
      <div className="card-container">
        {services.map((service, index) => (
          <ServiceCardItem key={index} {...service} />
        ))}
      </div>
    </div>
  );
};

const ServiceCardItem = ({ title, description, imageSrc }) => (
  <div className="card">
    <img className="card-image" src={process.env.PUBLIC_URL + imageSrc} alt="Service" />
    <div className="card-content">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  </div>
);

export default ServiceCard;