import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RequestPageIcon from '@mui/icons-material/RequestPage';

// Styled components for a modern look
const Container = styled('div')({
  textAlign: 'center',
  marginTop: 50,
  marginBottom: 50,
});

const SectionTitle = styled(Typography)({
  marginBottom: 50,
  fontSize: 26,
  fontWeight: 'bold',
});

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '40px',
});

const StyledCard = styled(Paper)({
  width: 300,
  padding: 20,
  paddingTop: 60, // Extra padding to accommodate the icon
  borderRadius: 15,
  boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
  transition: 'transform 0.3s ease-in-out',
  position: 'relative', // Ensure icon can be positioned absolutely
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 8px 30px rgba(0,0,0,0.4)', // Enhance shadow on hover
  },
  '@media (max-width: 600px)': {
    width: '90%', // Adjust width for smaller screens
    maxWidth: 300, // Limit maximum width for better scaling on very small screens
  },
});

const IconContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: '#1976d2',
  color: '#fff',
  position: 'absolute',
  top: -30, // Position icon to protrude above the card
  left: 'calc(50% - 40px)', // Center the icon horizontally
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
});

const Label = styled(Typography)({
  fontSize: 18,
  marginTop: 30, // Adjust margin to account for the icon space
});

const Value = styled(Typography)({
  fontSize: 36,
  fontWeight: 'bold',
  marginTop: 10,
});

const StatCard = ({ value, label, icon }) => (
  <StyledCard>
    <IconContainer>
      {icon}
    </IconContainer>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Box>
  </StyledCard>
);

const App = () => {
  const [clienti, setClienti] = useState(0);
  const [pratiche, setPratiche] = useState(0);
  const [richieste, setRichieste] = useState(0);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = async () => {
    try {
      const res = await axios.get('/api/homestats');
      const { success, stats } = res.data;
      if (success) {
        setClienti(stats.clienti);
        setPratiche(stats.pratiche);
        setRichieste(stats.richieste);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <SectionTitle>Risultati</SectionTitle>
      <CardContainer>
        <StatCard value={clienti} label="Clienti" icon={<PeopleIcon fontSize="large" />} />
        <StatCard value={pratiche} label="Pratiche" icon={<AssignmentIcon fontSize="large" />} />
        <StatCard value={richieste} label="Richieste" icon={<RequestPageIcon fontSize="large" />} />
      </CardContainer>
    </Container>
  );
};

export default App;
