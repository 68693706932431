import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import axios from "axios";
import utils from "../../utils";

const PieCharts = () => {
  // Colori per le fette dei grafici
  const COLORS = ["#02CA00", "#F93513"];

  // Stato per memorizzare il valore della fetta al passaggio del mouse
  const [activeItem, setActiveItem] = useState(null);
  const [durcData, setDurcData] = useState([]);
  const [edilConnectData, setEdilConnectData] = useState([]);
  const [noteInpsData, setNoteInpsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCharts();
  }, []);

  const loadCharts = async () => {
    try {
      const res = await axios.get(`/api/entistats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, stats } = res.data;
      if (success) {
        setDurcData([{ name: "Fatte", value: stats.durc.fatte }, { name: "Da fare", value: stats.durc.daFare }]);
        setEdilConnectData([{ name: "Fatte", value: stats.edil.fatte }, { name: "Da fare", value: stats.edil.daFare }]);
        setNoteInpsData([{ name: "Fatte", value: stats.inps.fatte }, { name: "Da fare", value: stats.inps.daFare }]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  // Funzione per gestire il passaggio del mouse su una fetta
  const handlePieMouseEnter = (data, index) => {
    setActiveItem(data[index]);
  };

  if (isLoading) {
    return (
      <Stack sx={{ my: 3 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-around' }}>
      <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 0 } }}>
        <Typography variant="h6">DURC</Typography>
        <PieChart width={200} height={200}>
          <Pie
            data={durcData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={2}
            onMouseEnter={handlePieMouseEnter}
          >
            {durcData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                cornerRadius={5}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
          <Legend />
        </PieChart>
        {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
      </Box>
      <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 0 } }}>
        <Typography variant="h6">Edil Connect</Typography>
        <PieChart width={200} height={200}>
          <Pie
            data={edilConnectData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#82ca9d"
            dataKey="value"
            paddingAngle={2}
            onMouseEnter={handlePieMouseEnter}
          >
            {edilConnectData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                cornerRadius={5}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
          <Legend />
        </PieChart>
        {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Note Inps</Typography>
        <PieChart width={200} height={200}>
          <Pie
            data={noteInpsData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={2}
            onMouseEnter={handlePieMouseEnter}
          >
            {noteInpsData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                cornerRadius={5}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
          <Legend />
        </PieChart>
        {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
      </Box>
    </Box>
  );
};

export default PieCharts;