import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import {
  Box,
  Container,
  TableContainer,
  Typography,
  ButtonBase,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Stack,
  CircularProgress,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Autocomplete,
  FormControl,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";

const dataRowSX = {
  display: "table-row",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

// filtro delle aziende
const filter = (aziende, search) => {
  const filtered = aziende.filter(a => a.ragione_sociale.toLowerCase()
    .replace(/[^\p{L}\p{N}]/gu, '')
    .includes(search.toLowerCase().replace(/[^\p{L}\p{N}]/gu, '')));
  return filtered;
}

const ListaAziende = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [userTotal, setUserTotal] = useState(0);
  const [userPage, setUserPage] = useState(0);
  const [userLoading, setUserLoading] = useState(true);
  const [statusMessageUsers, setStatusMessageUsers] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [masters, setMasters] = useState([{ id: -1, nome: 'Tutti' }, { id: 0, nome: 'Studio Romeo' }]);
  const [master, setMaster] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newUser, setNewUser] = useState({
    nome: "",
    cognome: "",
    ragione_sociale: "",
    telefono: "",
    partita_iva: "",
    indirizzo: "",
    citta: "",
    cap: "",
    provincia: "",
    email: "",
    pec: "",
    commercialista: "",
    password: "",
    conferma_password: "",
    master: 0,
  });

  useEffect(() => {
    loadData();
    loadUsers(-1);
    loadMasters();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && me.op) {
          setIsLoading(false);
          return; // stay on the page
        } else if (error) {
          setStatusMessageUsers(error);
        }
      }
    } catch (error) {
      setStatusMessageUsers(error);
    }
    setIsLoading(false);
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadUsers = async (newMaster) => {
    setStatusMessageUsers("");
    try {
      let string = `/api/getusers`;
      if (newMaster > -1) {
        string += '?master=' + newMaster;
      }
      const res = await axios.get(string, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAllUsers(res.data.users);
        setUsers(res.data.users);
        setUserTotal(res.data.total);
      } else {
        setStatusMessageUsers(error);
      }
    } catch (error) {
      setStatusMessageUsers(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setUserLoading(false);
  };

  const loadMasters = async () => {
    try {
      const res = await axios.get(`/api/listamaster`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const baseMasters = [{ id: -1, nome: 'Tutti' }, { id: 0, nome: 'Studio Romeo' }];
        baseMasters.push(...res.data.masters);
        setMasters(baseMasters);
      } else {
        setStatusMessageUsers(error);
      }
    } catch (error) {
      setStatusMessageUsers(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setUserLoading(false);
  };

  const handleMasterChange = (newMaster) => {
    setUserLoading(true);
    setMaster(newMaster);
    setUserPage(0);
    setSearchTerm("");
    loadUsers(newMaster);
  };

  const handleUserPage = (forward) => {
    if (userPage === 0 && !forward) {
      return;
    }
    const newPage = userPage + (forward ? 1 : -1);
    const filteredUsers = filter(allUsers, searchTerm);
    setUsers(filteredUsers.slice(newPage * 15, (newPage + 1) * 15));
    setUserTotal(filteredUsers.length);
    setUserPage(newPage);
  };

  const handleAddDialogToggle = () => {
    setOpenAddDialog(!openAddDialog);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    setUserLoading(true);
    setOpenAddDialog(false);
    try {
      const res = await axios.post("/api/adduser", newUser, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        // Clear the form
        setNewUser({
          nome: "",
          cognome: "",
          ragione_sociale: "",
          telefono: "",
          partita_iva: "",
          indirizzo: "",
          citta: "",
          cap: "",
          provincia: "",
          email: "",
          pec: "",
          commercialista: "",
          password: "",
          conferma_password: "",
          master: 0,
        });
        setUserPage(0);
        setSearchTerm("");
        loadUsers(-1);
      } else {
        setStatusMessageUsers(error);
        setUserLoading(false);
      }
    } catch (error) {
      setStatusMessageUsers(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      setUserLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Stack sx={{ mt: 5 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Container maxWidth="xl">
          <Box
            borderRadius={2}
            border="1px solid #ccc"
            sx={{ px: 3, pt: 3, pb: 2, mt: 5 }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, marginBottom: 2 }}
            >
              Clienti Studio: {userTotal}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                    <TextField
                      value={searchTerm}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        const filteredUsers = filter(allUsers, newValue);
                        setUsers(filteredUsers.slice(0, 15));
                        setUserTotal(filteredUsers.length);
                        setUserPage(0);
                        setSearchTerm(newValue);
                      }}
                      label="Cerca per Ragione Sociale"
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1, mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                    <Autocomplete
                      id="master"
                      value={master}
                      onChange={(event, newValue) => {
                        handleMasterChange(newValue)
                      }}
                      options={masters.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = masters.filter(az => az.id == id)[0];
                        if (a.id > 0) {
                          return `${a.nome} (${a.id})`;
                        }
                        return a.nome;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Studio"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

              </Grid>
            </Box>
            <Button variant="contained" onClick={handleAddDialogToggle}>
              Aggiungi cliente
            </Button>

            <Dialog open={openAddDialog} onClose={handleAddDialogToggle}>
              <DialogTitle>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    Aggiungi Cliente
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Autocomplete
                        id="master"
                        value={newUser.master}
                        onChange={(event, newValue) => {
                          setNewUser({ ...newUser, master: newValue });
                        }}
                        options={masters.filter(m => m.id >= 0).map((az) => az.id)}
                        getOptionLabel={(id) => {
                          const a = masters.find((az) => az.id === id);
                          return a ? (a.id > 0 ? `${a.nome} (${a.id})` : a.nome) : '';
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sotto lo Studio"
                            variant="outlined"
                            size="medium"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogTitle>

              <Divider sx={{ my: 0 }} />

              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nome"
                      name="nome"
                      value={newUser.nome}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Cognome"
                      name="cognome"
                      value={newUser.cognome}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Ragione Sociale"
                      name="ragione_sociale"
                      value={newUser.ragione_sociale}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Telefono"
                      name="telefono"
                      value={newUser.telefono}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Partita IVA"
                      name="partita_iva"
                      value={newUser.partita_iva}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Indirizzo"
                      name="indirizzo"
                      value={newUser.indirizzo}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Città"
                      name="citta"
                      value={newUser.citta}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="CAP"
                      name="cap"
                      value={newUser.cap}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Provincia"
                      name="provincia"
                      value={newUser.provincia}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={newUser.email}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="PEC"
                      name="pec"
                      value={newUser.pec}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Commercialista"
                      name="commercialista"
                      value={newUser.commercialista}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="password"
                      label="Password"
                      name="password"
                      value={newUser.password}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="password"
                      label="Conferma Password"
                      name="conferma_password"
                      value={newUser.conferma_password}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddDialogToggle}>Annulla</Button>
                <Button variant="contained" color="primary" onClick={handleAddUser}>
                  Aggiungi cliente
                </Button>
              </DialogActions>
            </Dialog>

            {userLoading ? (
              <Stack sx={{ my: 3 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (
              <div>
                <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
                  <Table size="small" sx={{ mt: 3 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          ID
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Nome
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Cognome
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Ragione Sociale
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Telefono
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Email
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <ButtonBase
                          component={Link}
                          to={`/op/dettaglioazienda?id=${user.id}`}
                          key={user.id}
                          sx={dataRowSX}
                        >
                          <TableCell>{user.id}</TableCell>
                          <TableCell>{user.nome}</TableCell>
                          <TableCell>{user.cognome}</TableCell>
                          <TableCell>{user.ragione_sociale}</TableCell>
                          <TableCell>{user.telefono}</TableCell>
                          <TableCell>{user.email}</TableCell>
                        </ButtonBase>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  />
                  <Typography
                    fontSize="1.1rem"
                    sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
                  >
                    {`${userPage * 15 + 1} - ${userPage * 15 + users.length
                      } di ${userTotal}`}
                  </Typography>
                  <IconButton
                    onClick={() => handleUserPage(false)}
                    disabled={userPage === 0}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <IconButton
                    onClick={() => handleUserPage(true)}
                    disabled={15 * (userPage + 1) >= userTotal}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Stack>
              </div>
            )}
            {statusMessageUsers && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  my: 1,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                }}
                role="alert"
              >
                {statusMessageUsers}
              </Paper>
            )}
          </Box>
        </Container>
      )}
    </div>
  );
};

export default ListaAziende;
