import React, { useState, useEffect } from 'react';
import {
  TextField, Autocomplete, Paper, Select, MenuItem, InputLabel,
  FormControl, Button, Typography, Box, Container, Grid, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Divider, CircularProgress,
  Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import utils from "../../utils";

const OpAmmFatture = () => {
  const [azienda, setAzienda] = useState(null);
  const [aziende, setAziende] = useState([]);;
  const [anno, setAnno] = useState(new Date().getFullYear());
  const [fatture, setFatture] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [avvisoId, setAvvisoId] = useState('');

  const anni = ['2024'];
  while (new Date().getFullYear() > anni[anni.length - 1]) {
    anni.push(new Date().getFullYear());
  }

  useEffect(() => {
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
    if (process.env.REACT_APP_ENV == "test") {
      setAziende([{ id: 1, nome: 'Azienda Test' }]);
    }
  }

  const loadData = async (user, year) => {
    try {
      const res = await axios.get(`/api/fattureop?user=${user}&anno=${year}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setFatture(res.data.fatture);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleAnnoChange = (nuovoAnno) => {
    if (!azienda) {
      return;
    }
    setLoading(true);
    setError("");
    setAnno(nuovoAnno);
    loadData(azienda, nuovoAnno);
  };

  const handleAziendaChange = (nuovaAzienda) => {
    setLoading(true);
    setError("");
    setAzienda(nuovaAzienda);
    loadData(nuovaAzienda, anno);
  };

  const handleOpenFileDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAvvisoId('');
  };

  const handleFileChange = (e) => {
    const f = e.target.files[0];
    setFile(f);
  };

  const downloadDoc = async (docId) => {
    setError("");
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setError("File non trovato.");
    }
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = new FormData();

      const json = {
        userId: azienda,
        avvisoId,
        doc: file.name
      };

      payload.append("doc", file);
      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/creafattura", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        return loadData(azienda, anno);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    handleCloseDialog();
    setLoading(false);
  }

  const handleUploadF24 = async (e, fatturaId) => {
    e.preventDefault();
    setLoading(true);
    try {
      const file = e.target.files[0];
      if (!file) return;

      const payload = new FormData();

      const json = {
        userId: azienda,
        id: fatturaId,
        doc: file.name
      };

      payload.append("doc", file);
      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/caricafatturaf24", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        return loadData(azienda, anno);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <Container maxWidth="xxl">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Gestione Fatture
        </Typography>
        <Box sx={{ marginBottom: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="azienda"
                value={azienda}
                onChange={(event, newValue) => {
                  handleAziendaChange(newValue)
                }}
                options={aziende.map((az) => az.id)}
                getOptionLabel={(id) => {
                  const a = aziende.filter(az => az.id == id)[0];
                  return `${a.nome} (${a.id})`;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Azienda"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Anno</InputLabel>
                <Select value={anno} onChange={(e) => handleAnnoChange(e.target.value)} label="Anno">
                  {anni.map((anno, index) => (
                    <MenuItem key={index} value={anno}>
                      {anno}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenFileDialog}
                disabled={!azienda || !anno}
                fullWidth
                sx={{ height: '50px' }} // Altezza fissa per uniformare con i selettori
              >
                Carica Fatture
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loading && <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />}
        {error && (
          <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        <TableContainer sx={{ marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Azienda</TableCell>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">ID Avviso di Parcella</TableCell>
                <TableCell align="center">F24</TableCell>
                <TableCell align="center">Fattura</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fatture.map((fattura, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell align="center">{aziende.filter(az => az.id == azienda)[0].nome}</TableCell>
                    <TableCell align="center">{fattura.date}</TableCell>
                    <TableCell align="center">
                      {fattura.avviso_id ? <Button
                        variant="text"
                        onClick={() => window.location.href = `/op/amministrazione/avvisiparcella/detail?id=${fattura.avviso_id}`}
                      >
                        {fattura.avviso_id}
                      </Button> : null}
                    </TableCell>
                    <TableCell align="center">
                      {fattura.doc_f24 ? (
                        <Button
                          variant="text"
                          onClick={() => downloadDoc(fattura.doc_f24)}
                          startIcon={<DownloadIcon />}
                        >
                          Scarica F24
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => document.getElementById(`upload-f24-${fattura.id}`).click()}
                          startIcon={<UploadIcon />}
                        >
                          Carica F24
                        </Button>
                      )}
                      <input
                        type="file"
                        id={`upload-f24-${fattura.id}`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleUploadF24(e, fattura.id)}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => downloadDoc(fattura.doc)}
                        startIcon={<DownloadIcon />}
                      >
                        Scarica
                      </Button>
                    </TableCell>

                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog per Carica Fatture */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Carica Fattura</DialogTitle>
          <DialogContent>
            <Box component="form" noValidate>
              <TextField
                autoFocus
                margin="dense"
                id="fatturaId"
                label="ID Avviso di Parcella"
                type="number"
                fullWidth
                variant="outlined"
                value={avvisoId}
                onChange={(e) => setAvvisoId(e.target.value)}
              />
              <Box sx={{ marginTop: 2 }}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  required
                />
                {file && (
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                    {file.name}
                  </Typography>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Annulla
            </Button>
            <Button
              onClick={handleUploadFile}
              color="primary"
              disabled={!file}
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default OpAmmFatture;
