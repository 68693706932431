import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Box
} from "@mui/material";
import axios from "axios";
import utils from "../../utils";

const PagheFatte = () => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const anni = ['2024'];
  while (now.getFullYear() > anni[anni.length - 1]) {
    anni.push((Number(anni[anni.length - 1]) + 1).toString());
  }
  const [pagheFatteData, setPagheFatteData] = useState([]);
  const [aziende, setAziende] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedAzienda, setSelectedAzienda] = useState('');

  useEffect(() => {
    // loadAziende();
    loadPagheFatte();
  }, [selectedMonth, selectedYear]);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, data } = res.data;
      if (success) {
        setAziende(data);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const loadPagheFatte = async () => {
    try {
      const anno = selectedMonth == 0 ? Number(selectedYear) - 1 : selectedYear;
      const mese = selectedMonth == 0 ? 11 : Number(selectedMonth) - 1;
      const res = await axios.get(`/api/paghefatte?anno=${anno}&mese=${mese}&azienda=${selectedAzienda}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, data } = res.data;
      if (success) {
        setPagheFatteData(data);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  return (
    <Box p={3}>
      <Typography variant="h5" align="center" gutterBottom>
        Paghe Fatte
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4} lg={3}>
          <FormControl fullWidth>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Seleziona Mese' }}
              style={{ textAlign: 'center' }}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i} value={i}>
                  {new Date(0, i).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl fullWidth>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Seleziona Anno' }}
              style={{ textAlign: 'center' }}
            >
              {anni.map(a =>
                <MenuItem key={a} value={a}>
                  {a}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={3}>
          <FormControl fullWidth>
            <Select
              value={selectedAzienda}
              onChange={(e) => setSelectedAzienda(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Seleziona Azienda' }}
              style={{ textAlign: 'center' }}
            >
              <MenuItem value="">
                <em>Tutte le Aziende</em>
              </MenuItem>
              {aziende.map((azienda) => (
                <MenuItem key={azienda.id} value={azienda.id}>
                  {azienda.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
      <TableContainer component={Box} sx={{ borderRadius: '8px', marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#333' }}>
              <TableCell style={{ color: '#fff', width: '33%', textAlign: 'center' }}>Azienda</TableCell>
              <TableCell style={{ color: '#fff', width: '33%', textAlign: 'center' }}>Numero Dipendenti</TableCell>
              <TableCell style={{ color: '#fff', width: '34%', textAlign: 'center' }}>Completate da</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={3} align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              pagheFatteData.map((row) => (
                <TableRow key={row.nome}>
                  <TableCell style={{ textAlign: 'center' }}>{row.nome}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{row.dipendenti}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{row.presaInCarico ? row.presaInCarico : ""}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PagheFatte;
