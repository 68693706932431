import React from 'react';
import TabellaPresenze from './OpPagPresenzeTab';

function OpPagPresenze() {
  return (
    <div>
      <TabellaPresenze /> 
    </div>
  );
}

export default OpPagPresenze;
