import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Privacy = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography paragraph>
          Il rispetto della tua privacy è fondamentale per Studio Romeo. Ci impegniamo a mantenere la tua fiducia proteggendo le informazioni personali che ci fornisci. Questa politica riguarda la tua privacy in relazione all'utilizzo del nostro sito web studiosoniaromeo.it e altri servizi correlati.
        </Typography>
        <Typography paragraph>
          Raccogliamo solo le informazioni personali necessarie per fornirti i nostri servizi, in conformità con gli accordi inizialmente accettati tra lo studio e il cliente. Le informazioni vengono raccolte in modo equo e legale, con il tuo consenso esplicito e consapevolezza.
        </Typography>
        <Typography paragraph>
          Le informazioni raccolte vengono utilizzate esclusivamente per gli scopi specificati e conservate solo per il tempo necessario a fornire il servizio richiesto. Implementiamo misure di sicurezza adeguate per proteggere le informazioni personali da accessi, divulgazioni, alterazioni o distruzioni non autorizzate.
        </Typography>
        <Typography paragraph>
          Non condividiamo le tue informazioni personali con terzi, tranne quando richiesto dalla legge o quando necessario per l'esecuzione dei servizi concordati con te.
        </Typography>
        <Typography paragraph>
          Hai il diritto di rifiutare la nostra richiesta di informazioni personali, tuttavia, ciò potrebbe influire sulla nostra capacità di fornirti alcuni servizi.
        </Typography>
        <Typography paragraph>
          Utilizzando il nostro sito, accetti le pratiche descritte in questa pagina. Se hai domande o dubbi sulla nostra gestione dei dati degli utenti, ti preghiamo di contattarci.
        </Typography>
        <Typography paragraph>
          Questa politica ha effetto dal momento in cui si accede al sito studiosoniaromeo.it.
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
