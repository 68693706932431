import React, { useState } from "react";
import { Paper, Typography, TextField, Button, Grid, Divider, Stack, CircularProgress } from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import JSZip from "jszip";

const NoteDiRettificaINPS = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [descrizione, setDescrizione] = useState("");

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    if (files.length == 1) {
      // per un file non genera lo zip, solo se sono piu di uno
      setUploadedFile({ name: files[0].name, file: files[0] });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setUploadedFile({ name: 'documenti.zip', zippedBlob });
    });
  }

  const handleConfirmChanges = async (e) => {
    e.preventDefault();
    setStatusMessage("");
    setLoading(true);
    try {
      const payload = new FormData();

      const json = {
        descrizione,
        docInps: uploadedFile.name
      };

      if (uploadedFile) {
        payload.append("doc", uploadedFile.file || uploadedFile.zippedBlob);
      }

      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/creanoteinps", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        window.location.href = "/success?backto=%2Fcli%2Fenti";
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "auto", marginTop: "20px" }}>
      <Grid item xs={12} md={9} xl={6}>
        <Paper elevation={4} style={{ padding: 20 }}>

          <Typography variant="h4" gutterBottom textAlign="center" style={{ margin: "2px" }}>
            Nota di Rettifica INPS
          </Typography>

          <Divider style={{ margin: "20px 0" }} />

          <TextField
            label="Descrizione Note di Rettifica INPS"
            variant="outlined"
            fullWidth
            value={descrizione}
            onChange={(e) => setDescrizione(e.target.value)}
            multiline
            rows={6}
            margin="normal"
          />

          <Divider style={{ margin: "20px 0" }} />

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <input
                type="file"
                name="doc"
                onChange={handleMultiFileChange}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
            </Grid>
          </Grid>

        </Paper>

        {(statusMessage) && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              my: 2,
              mx: 2,
              flexGrow: 1,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}

        {loading ? (
          <Stack sx={{ my: 3 }} alignItems="center">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
            <Button variant="contained" color="primary" disabled={!uploadedFile} onClick={handleConfirmChanges}>
              Invia
            </Button>
          </Grid>
        )}

      </Grid>
    </Grid>
  );
};

export default NoteDiRettificaINPS;
