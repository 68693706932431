module.exports = {
  "items": [
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di novembre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 4935270,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di novembre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 4935269,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-02T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (IV trimestre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064574,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (IV trimestre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064441,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MODELLO 730 – ASSISTENZA FISCALE PRESTATA DAL SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">il sostituto d'imposta comunica se intende prestare assistenza fiscale diretta</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064552,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-15T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d'appalto nei confronti dei condomini nonché sull'ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (dicembre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064586,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di dicembre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064542,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di dicembre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064377,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di dicembre 2023:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS: committenti</li></ul></li>\r\n  <li>versamento all'INPS dei contributi per i coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al IV trimestre 2023</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064575,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (prima rata o unica soluzione) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099096,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (IV trimestre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064588,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-22T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (dicembre 2023) e trimestrali (IV trimestre 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064589,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di dicembre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064590,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI AL SISTEMA TS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"color: black; font-size: 11pt;\">In base allo schema di Decreto Legislativo di riforma degli adempimenti tributari a partire dal 2024 la trasmissione dei dati al sistema TS sarà con cadenza semestrale entro i termini che saranno stabiliti con Decreto Ministeriale</span>. </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064657,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI EDITORIA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per la presentazione delle domande di ammissione ai contributi per l'anno 2023 in favore delle imprese editrici di quotidiani, nazionali e diffusi all'estero, e di periodici nazionali.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5130730,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di dicembre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064593,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064597,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COLLOCAMENTO OBBLIGATORIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio del prospetto informativo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064591,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all'Agenzia delle Dogane dell'istanza relativa al IV trimestre 2023 per il rimborso/compensazione del maggior onere derivante dall'incremento dell'accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064594,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all'Elenco B predisposto dall'Agenzia delle entrate e relativo alle fatture del IV trimestre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064596,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-01-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">BONUS PUBBLICITA'</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione dichiarazione sostitutiva relativa agli investimenti effettuati nell'anno 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5078493,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-09T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TFR - IMPOSTA SOSTITUTIVA SULLA RIVALUTAZIONE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento a saldo sulle rivalutazioni del TFR maturate nell’anno 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064556,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di gennaio 2024 e IV trimestre 2023 (trimestrali speciali)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064551,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di gennaio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064378,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di gennaio 2024:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064442,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IV quota fissa 2023 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064553,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento premio (regolarizzazione 2023 e anticipo 2024) o I rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064555,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (gennaio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064535,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (gennaio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064534,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi IV trimestre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064557,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (gennaio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064558,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-26T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE/ADESIONE O REVOCA DEL REGIME AGEVOLATO CONTRIBUTIVO FORFETARI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">al fine di fruire del regime agevolato contributivo nel 2024, o di revocare lo stesso per fuoriuscita 2024, i contribuenti forfetari trasmettono telematicamente all’INPS l’apposita domanda</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064450,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">BONUS ACQUA POTABILE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">comunicazione telematica all’Agenzia delle Entrate delle spese agevolabili sostenute nel 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064451,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-28T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">REGISTRI CONTABILI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">stampa o conservazione digitale del libro giornale, mastro, inventari, registri IVA e registro dei beni ammortizzabili relativi al 2022; in alternativa, è possibile stampare i registri solo a seguito della richiesta avanzata in sede di controllo (art. 7, comma 4-quater, D.L. n. 357/1994).</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064446,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell'imposta ancora dovuta dai contribuenti con immobili in Comuni che hanno pubblicato le aliquote 2023 entro il 15.01.2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5131401,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FATTURE ELETTRONICHE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine ultimo per la conservazione digitale delle fatture elettroniche 2022</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064448,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il IV trimestre 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064449,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al IV trimestre 2023 (detta comunicazione può essere compresa nella Dichiarazione annuale IVA da presentare entro il mese di febbraio)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064452,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS – ex DMAG) di gennaio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064453,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (I trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064454,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064458,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di gennaio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064457,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica delle dichiarazioni delle retribuzioni 2023 con eventuale domanda di riduzione del tasso medio di tariffa</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064455,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONSERVAZIONE DIGITALE DICHIARAZIONI FISCALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Termine entro cui effettuare la conservazione digitale delle dichiarazioni fiscali relative al 2022 ai sensi del D.M. 17/6/2014.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064447,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-02-29T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (febbraio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064460,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI RELATIVI AD ONERI DA PARTE DI SOGGETTI TERZI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio all’Agenzia delle Entrate dei dati relativi a oneri deducibili/detraibili sostenuti nell’anno precedente da ciascun contribuente da parte di: istituti bancari, assicurazioni, agenzie funebri, università, asili nido pubblici e privati e amministratori di condomini (in relazione a parti comuni di edifici residenziali) ecc.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064465,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI RELATIVI AD EROGAZIONI LIBERALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">ONLUS, APS, fondazioni/associazioni riconosciute aventi per scopo statutario la tutela, promozione e la valorizzazione dei beni di interesse artistico, storico e paesaggistico, e fondazioni/associazioni riconosciute aventi per scopo statutario la tutela, promozione e la valorizzazione dei beni di interesse artistico, storico e paesaggistico inviano telematicamente, in via facoltativa, all’Agenzia delle Entrate una comunicazione contenente i dati relativi alle erogazioni liberali in denaro deducibili e detraibili eseguite nell’anno precedente da persone fisiche. L’invio è obbligatorio se risultano ricavi, rendite, proventi o entrate comunque denominate superiori a 220.000 euro</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064466,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI DA PARTE DI AMMINISTRATORI DI CONDOMINIO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">gli amministratori di condominio inviano all'Agenzia delle Entrate i dati relativi a interventi di recupero del patrimonio edilizio e di riqualificazione energetica nonché di acquisto di mobili e grandi elettrodomestici relativamente alle parti comuni degli edifici residenziali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085344,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><ul>\r\n  <li>liquidazione e versamento (mese di febbraio 2024)</li>\r\n  <li>versamento imposta a saldo risultante da dichiarazione annuale IVA relativa al 2023</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064379,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI SPESE VETERINARIE AL SISTEMA TS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">i veterinari trasmettono telematicamente al sistema Tessera Sanitaria i dati relativi alle spese veterinarie sostenute nel 2023 ai fini della predisposizione del mod. 730/REDDITI 2024 PF precompilato.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5104153,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (febbraio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064459,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TASSA ANNUALE LIBRI CONTABILI E SOCIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento da parte delle società di capitali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064463,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE CESSIONE CREDITO/SCONTO IN FATTURA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio all’Agenzia delle Entrate della comunicazione di cessione del credito/sconto in fattura relativa alle spese sostenute nel periodo d’imposta precedente per gli interventi edilizi agevolati.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064468,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INVIO CU 2024 ORDINARIA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per l’invio telematico all’Agenzia delle Entrate della CU 2024 ordinaria, nonché degli ulteriori dati fiscali e contributivi, dei dati necessari per l’attività di controllo dell’Agenzia delle Entrate e degli enti previdenziali e assicurativi, dei dati contenuti nelle certificazioni rilasciate ai soli fini contributivi e assicurativi e delle informazioni relative alle operazioni di conguaglio effettuate a seguito dell’assistenza fiscale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064469,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di febbraio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064380,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CU 2024 SINTETICA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">consegna certificazione dei redditi di lavoro dipendente e assimilati, redditi diversi e da locazione breve percepiti nell’anno 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064470,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONGUAGLIO SOSTITUTI D’IMPOSTA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle ritenute alla fonte inerenti le operazioni di conguaglio eseguite nel corso del mese di febbraio 2024 da parte dei datori di lavoro e committenti sui redditi corrisposti nel corso dell’anno solare 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064462,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di febbraio 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al III trimestre 2023</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064444,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CERTIFICAZIONI UTILI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">consegna ai percettori delle certificazioni relative agli utili corrisposti nel 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064464,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-03-18T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INVIO CORRETTIVO CU 2024 ORDINARIA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">a seguito di errato invio delle Certificazioni, è possibile effettuare la trasmissione delle CU correttive, senza incorrere in sanzioni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064471,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE CORRETTIVA DATI RELATIVI AD ONERI DA PARTE DI SOGGETTI TERZI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">in caso di errata comunicazione (il termine del 16 marzo per il 2024 è prorogato al 18), i soggetti obbligati alla trasmissione dei dati utili per la predisposizione del Mod. 730 precompilato (banche, assicurazioni, enti previdenziali ed enti di previdenza complementare, ecc.) possono provvedere all’invio all’Agenzia delle Entrate dei dati corretti senza incorrere in sanzioni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064472,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-03-21T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (febbraio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064473,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-03-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064581,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-03-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">SANATORIA RATE ISTITUTI DEFINITORI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 5° rata per la regolarizzazione dell’omesso/insufficiente versamento delle somme dovute per gli istituti definitori (comma 219, Legge n. 197/2022)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085343,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di febbraio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064578,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS – ex DMAG) di febbraio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064474,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DEFINIZIONE LITI FISCALI PENDENTI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamenti della 4° rata degli importi dovuti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099202,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">BONUS PUBBLICITÀ:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione domanda telematica contenente i dati relativi agli investimenti già effettuati o da effettuare nell'anno 2024, tramite specifica piattaforma resa disponibile dall’Agenzia delle entrate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064580,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FIRR:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento del contributo annuale al fondo indennità risoluzione rapporto di agenzia</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064475,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">SANATORIA IRREGOLARITA' FORMALI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamento della II° rata di quanto dovuto per la sanatoria delle irregolarità formali commesse fino al 31 ottobre 2022</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085342,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RAVVEDIMENTO SPECIALE</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 5° rata per la regolarizzazione delle violazioni riferite alle dichiarazioni relative al periodo d’imposta in corso al 31.12.2021 e periodi d’imposta precedenti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085340,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VARIAZIONE DATI MOD. EAS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. EAS per comunicare le variazioni dati verificatesi nel 2023, rispetto a quanto già comunicato</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064579,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-02T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE OPERAZIONI IN CONTANTI LEGATE AL TURISMO (contribuenti mensili):</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. Polivalente (quadro TU) da parte di commercianti al minuto ed agenzie di viaggio, per comunicare i corrispettivi (compresi tra euro 2.000 ed euro 15.000) relativi ad operazioni in contanti effettuate nell'anno 2023 da parte di turisti appartenenti all’UE (non residenti in Italia) ed extraUE.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064479,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (I trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064477,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (I trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064478,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (marzo 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064481,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (marzo 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064480,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di marzo 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064482,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di marzo 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064483,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi mese di marzo 2024:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064484,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE OPERAZIONI IN CONTANTI LEGATE AL TURISMO (contribuenti trimestrali):</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. Polivalente (quadro TU) da parte di commercianti al minuto ed agenzie di viaggio, per comunicare i corrispettivi (compresi tra euro 2.000 ed euro 15.000) relativi ad operazioni in contanti effettuate nell'anno 2023 da parte di turisti appartenenti all’UE (non residenti in Italia) ed extraUE</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064487,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (I trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064486,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-22T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (marzo 2024) e trimestrali (I trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064488,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-26T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamento dell’imposta di bollo su scritture contabili conservate digitalmente, tramite Mod. F24 telematico</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5130847,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-29T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA – RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">richiesta IVA a credito del I trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064493,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di marzo 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085909,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al I trimestre 2024 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064436,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle entrate e relativo alle fatture del I trimestre</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064490,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064489,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione della dichiarazione IVA relativa al 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064491,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">STRUTTURE SANITARIE PRIVATE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico all’Agenzia delle Entrate del Mod. SSP per la comunicazione dei compensi riscossi nel 2023 da parte delle strutture sanitarie private per l’attività medica/paramedica esercitata dai singoli professionisti nella struttura stessa</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064599,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PRECOMPILATA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Messa a disposizione al contribuente della dichiarazione precompilata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064600,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di marzo 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085910,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-04-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (aprile 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064602,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (aprile 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064601,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di aprile 2024 e I trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064603,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di aprile 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064604,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di aprile 2024:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064605,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento I quota fissa 2024 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064606,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064607,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-05-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi I trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064608,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-05-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (aprile 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064609,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-05-27T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al I trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064613,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese aprile 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064612,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il I trimestre 2024 (non sono interessati i contribuenti per cui l’imposta da versare risulta inferiore ad € 5.000)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064614,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (II trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064611,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione Iva IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064615,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di aprile 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064610,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-05-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di maggio 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al IV trimestre 2023</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064619,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamento delle ritenute operate nel periodo Gennaio - Maggio complessivamente inferiori a € 500.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5136786,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (maggio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064618,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (maggio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064617,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti entro il 31 maggio:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064616,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di maggio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064620,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento acconto (o, a discrezione del contribuente, in unica soluzione annuale) anno 2024.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064376,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di maggio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064621,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III rata 2023 (conguaglio) e I rata 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064559,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-06-17T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (maggio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064445,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-06-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064624,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE TERRENI E PARTECIPAZIONI ALL’1.1.2024</p><p class=\"Testo-scadenza\" lang=\"it-IT\">redazione / asseverazione della perizia di stima e versamento dell’imposta sostitutiva dovuta in unica soluzione / prima rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5130827,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-06-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RAVVEDIMENTO SPECIALE</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 6° rata per la regolarizzazione delle violazioni riferite alle dichiarazioni relative al periodo d’imposta in corso al 31.12.2021 e periodi d’imposta precedenti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085351,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dall'1 giugno al 20 giugno:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064560,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di maggio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064567,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza per chiedere a rimborso il credito relativo:</p><ul>\r\n  <li>al IV trimestre 2021 non compensato entro il 31/12/2023;</li>\r\n  <li>al l, II, III trimestre 2022 non compensato entro il 31/12/2023</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064530,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DEFINIZIONE LITI FISCALI PENDENTI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della 5° rata degli importi dovuti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085350,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta a saldo risultante da dichiarazione annuale relativa al 2023 con maggiorazione dello 0,4% per mese (o frazione) dal 16 marzo (1,6%)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064564,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ESTROMISSIONE IMMOBILE STRUMENTALE</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 2° rata pari al 40% dell'imposta sostitutiva dovuta per l'estromissione dell'immobile dal patrimonio dell'impresa immobiliare</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085611,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADEGUAMENTO ISA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IVA derivante dai maggiori ricavi/compensi dichiarati ai fini del miglioramento del profilo di affidabilità</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085840,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE TARI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) della dichiarazione TARI per le variazioni avvenute nel corso del 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064435,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMU:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) della dichiarazione IMU per le variazioni avvenute nel corso del 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064544,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) da parte degli Enti non commerciali della dichiarazione IMU per le variazioni avvenute nel corso del 2022 e del 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064623,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMPOSTA DI SOGGIORNO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per la presentazione della dichiarazione dell’imposta di soggiorno per l'anno d’imposta 2023.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064576,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE PER IMPATRIATI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento in unica soluzione di un importo pari al 5% o al 10% dei redditi di lavoro dipendente/autonomo prodotti in Italia (relativi al periodo d’imposta precedente a quello di esercizio dell’opzione)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064439,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. REDDITI 2024:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione presso un ufficio postale del Mod. REDDITI 2024 PF, da parte delle persone fisiche che possono presentare il modello cartaceo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064383,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 – SOGGETTI PRIVI DI SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle imposte risultanti dal Mod. 730 relativo a soggetti privi di sostituto d’imposta e a soggetti deceduti entro il 29 febbraio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064563,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LOCAZIONI BREVI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">i soggetti che esercitano attività di intermediazione immobiliare e quelli che gestiscono portali telematici (senza incasso canone e senza applicazione ritenuta), trasmettono all’Agenzia delle Entrate, i dati relativi ai contratti di locazione breve conclusi l’anno precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064349,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di maggio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064382,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI REDDITI 2024</p><ul>\r\n  <li>imposte risultanti dalla dichiarazione delle Persone Fisiche/Società di Persone periodo d’imposta 2023 (saldo 2023 e I rata acconto 2024)</li>\r\n  <li>imposte risultanti dalla dichiarazione dei soggetti IRES con esercizio coincidente con l’anno solare e approvazione bilancio nei termini ordinari (saldo 2023 e I rata acconto 2024)</li>\r\n  <li>diritto camerale annuale</li>\r\n  <li>imposte sostitutive: cedolare secca, IVIE, IVAFE, ecc.</li>\r\n  <li>saldo 2023 e I rata acconto 2024 dei contributi IVS artigiani e commercianti sul reddito eccedente il minimale</li>\r\n  <li>saldo 2023 e I rata acconto previdenziale 2024 da parte dei professionisti iscritti alla gestione separata INPS</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064561,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI MOD. IRAP 2024</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’IRAP (saldo 2023 e I acconto 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064562,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">SANATORIA RATE ISTITUTI DEFINITORI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 6° rata per la regolarizzazione dell’omesso / insufficiente versamento delle somme dovute per gli istituti definitori (comma 219, Legge n. 197/2022)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085352,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE BENI D’IMPRESA (Legge di Bilancio 2020 e D.L. n. 23/2020)</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della III° rata dell’imposta sostitutiva relativa alla rivalutazione dei beni d’impresa e partecipazioni risultanti dal bilancio dell'esercizio in corso al 31 dicembre 2018 e ancora presenti nel bilancio al 31 dicembre 2022</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064565,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute operate dal 12/2023 al 05/2024 da parte del condominio nel caso non sia superato il limite di euro 500 di ritenute operate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064622,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-01T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (II trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064438,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (II trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064437,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di giugno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064628,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di giugno 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per i coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al I trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064629,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (giugno 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064626,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (giugno 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064625,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di giugno 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064627,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (II trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064630,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-22T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dal 21 giugno al 15 luglio:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064424,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-23T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (giugno 2024) e trimestrali (II trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064631,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI MOD. IRAP 2024 con magg. 0.4%</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’IRAP (saldo 2023 e I acconto 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085842,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE BENI D’IMPRESA con magg. 0,4% </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento con magg. 0,4% della III° rata dell’imposta sostitutiva relativa alla rivalutazione dei beni d’impresa e partecipazioni risultanti dal bilancio dell'esercizio in corso al 31 dicembre 2018 e ancora presenti nel bilancio al 31 dicembre 2022 (Legge di Bilancio 2020 e D.L. n. 23/2020)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064637,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA - RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Richiesta IVA a credito del II trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064639,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064638,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 – SOGGETTI PRIVI DI SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle imposte (con maggiorazione dello 0,40%) risultanti dal Mod. 730 relativo a soggetti privi di sostituto d'imposta e a soggetti deceduti entro il 29 febbraio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064635,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADEGUAMENTO ISA con magg. 0,4%</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IVA derivante dai maggiori ricavi/compensi dichiarati ai fini del miglioramento del profilo di affidabilità fiscale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085843,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di giugno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064641,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al II trimestre 2024 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064642,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA con magg. 0,4%</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta a saldo risultante dalla dichiarazione annuale relativa al 2023, applicando l'ulteriore maggiorazione dello 0,4% sulla somma dovuta </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085844,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di giugno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064640,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTO REDDITI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposte sui redditi e sostitutive per le società di capitali che approvano il Bilancio a 180 giorni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064643,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI REDDITI 2024 con magg. 0,4%</p><ul>\r\n  <li>imposte risultanti dalla dichiarazione delle Persone Fisiche/Società di Persone periodo d’imposta 2023 (saldo 2023 e I rata acconto 2024)</li>\r\n  <li>imposte risultanti dalla dichiarazione dei soggetti IRES (saldo 2023 e I rata acconto 2024)</li>\r\n  <li>diritto camerale annuale</li>\r\n  <li>imposte sostitutive: cedolare secca, IVIE, IVAFE, ecc.</li>\r\n  <li>saldo 2023 e I rata acconto 2024 dei contributi IVS artigiani e commercianti sul reddito eccedente il minimale</li>\r\n  <li>saldo 2023 e I rata acconto previdenziale 2024 da parte dei professionisti iscritti alla gestione separata INPS</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085841,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-07-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DECORRENZA PERIODO DI SOSPENSIONE FERIALE DEI TERMINI PROCESSUALI</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064650,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-08-01T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064388,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di luglio 2024 e II trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5130845,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II quota fissa 2024 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064387,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi II trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064389,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (luglio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064443,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di luglio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064577,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di luglio 2024:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064386,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (luglio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064384,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-08-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (luglio 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064390,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-08-26T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTO REDDITI CON MAGG. 0,4%:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposte sui redditi e sostitutive (con maggiorazione 0,4%) per le società di capitali che approvano il Bilancio a 180 giorni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064391,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-08-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064396,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TERMINE DEL PERIODO DI SOSPENSIONE FERIALE DEI TERMINI PROCESSUALI</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064392,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-08-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di luglio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064394,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di luglio 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064395,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (III trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064393,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-09-02T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle Entrate e relativo alle fatture del II trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064397,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-09-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di agosto 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li><li>versamento all’INPS dei contributi per la manodopera agricola relativi al I trimestre 2024</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al II trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064403,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (agosto 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064399,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dal 16 luglio al 31 agosto:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064398,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di agosto 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064401,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (agosto 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064400,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di agosto 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064402,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-09-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (agosto 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064495,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-09-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">SANATORIA RATE ISTITUTI DEFINITORI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 7° rata per la regolarizzazione dell’omesso / insufficiente versamento delle somme dovute per gli istituti definitori (comma 219, Legge n. 197/2022)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064511,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE REDDITI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica del Mod. REDDITI 2024 da parte di persone fisiche, società di persone e società di capitali con esercizio coincidente con l’anno solare.  </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064363,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IRAP: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica della dichiarazione annuale IRAP. </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064364,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DEFINIZIONE LITI FISCALI PENDENTI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 6° rata degli importi dovuti </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064518,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064537,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"font-size: 1rem;\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al II trimestre 2024</span>\r\n</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064532,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il II trimestre 2024 se l’imposta da versare risulta superiore o uguale ad € 5.000; va considerata anche l'imposta sulle fatture elettroniche per il I trimestre 2024 se questa era inferiore ad € 5.000</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064645,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di agosto 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064644,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RAVVEDIMENTO SPECIALE</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 7° rata per la regolarizzazione delle violazioni riferite alle dichiarazioni relative al periodo d'imposta in corso al 31.12.2021 e periodi d'imposta precedenti </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064345,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE PER TRASPARENZA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione del Mod. REDDITI o di apposita comunicazione per l’adesione al regime di trasparenza da parte di società di capitali per il triennio 2023-2025. <span style=\"font-size: 1rem;\">In base allo schema di Decreto Legislativo di riforma degli adempimenti tributari la scadenza (presentazione del Mod. REDDITI) risulta anticipata rispetto alla data del 30 novembre. </span> </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064365,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di agosto 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064406,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica dell’istanza di rimborso dell’IVA assolta in altro Stato UE relativa al 2023 da parte di operatori residenti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064405,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dall'1 settembre al 30 settembre:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4.</li>\r\n</ul><p class=\"Testo-scadenza\" lang=\"it-IT\">I lavoratori dipendenti/pensionati possono trasmettere autonomamente la dichiarazione 730 tramite l'applicazione web disponibile sul sito internet dell'Agenzia delle Entrate.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064404,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-09-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (III trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064409,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">il dipendente comunica al datore di lavoro/ente pensionistico di effettuare un minor o nessun acconto IRPEF/cedolare secca</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064407,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (III trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064408,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-10-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (settembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064411,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (settembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064410,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di settembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064412,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di settembre 2024:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064414,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di settembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064413,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-10-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (III trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064415,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-10-21T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 INTEGRATIVO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">lavoratori/pensionati consegnano a CAF/professionista abilitato il Mod. 730 integrativo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064417,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (settembre 2024) e trimestrali (III trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064416,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-10-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle Entrate e relativo alle fatture del III trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064440,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CERTIFICAZIONE UNICA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico all’Agenzia delle Entrate da parte dei sostituti d’imposta della Certificazione Unica 2024 contenente esclusivamente redditi 2023 esenti o non dichiarabili tramite Mod. 730/2024 (ad esempio, compensi corrisposti a lavoratori autonomi titolari di partita IVA)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064423,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al III trimestre 2024 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064421,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA - RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">richiesta IVA a credito del III trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064418,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di settembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064420,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 770:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico dichiarazione relativa al 2023</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064422,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di settembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064419,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064375,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-10-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 INTEGRATIVO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">CAF e professionisti abilitati trasmettono in via telematica all’Agenzia delle Entrate  e consegnano ai dipendenti/pensionati<span style=\"font-size: 1rem;\"> Modd. 730 e 730-3 integrativi</span></p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064351,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-11T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE TERRENI E PARTECIPAZIONI</p><ul>\r\n  <li>versamento della II° rata e relativi interessi dell’imposta sostitutiva della rivalutazione delle partecipazioni e dei terreni posseduti al 1° gennaio 2023, non in regime di impresa, da parte di persone fisiche, società semplici e associazioni professionali, nonché enti non commerciali (Legge di Bilancio 2023);</li>\r\n  <li>versamento della III° rata e relativi interessi dell’imposta sostitutiva della rivalutazione delle partecipazioni e dei terreni posseduti al 1° gennaio 2022, non in regime di impresa, da parte di persone fisiche, società semplici e associazioni professionali, nonché enti non commerciali (D.L. n. 17/2022);</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064352,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CRIPTO-ATTIVITA'</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II° rata imposta sostitutiva rideterminazione del valore delle cripto-attività</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085353,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-15T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IV rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064359,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (ottobre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064353,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (ottobre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064354,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di ottobre 2024 e III trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064355,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di ottobre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064356,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di ottobre 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al III trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064357,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III quota fissa 2024 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064358,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-18T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi III trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064360,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (ottobre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064361,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064374,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">operazioni di conguaglio (sulla retribuzione erogata nel mese di novembre) dell’importo in acconto (II o unica rata)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064362,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-11-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di ottobre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064371,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il III trimestre 2024 nonché versamento dell’imposta di bollo sulle fatture elettroniche per il I e II trimestre 2024 da parte dei contribuenti per cui l’imposta risultava complessivamente inferiore ad € 5.000</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064373,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (IV trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064369,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di ottobre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064370,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata acconto 2024 sul reddito eccedente il minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064367,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">GESTIONE SEPARATA INPS – PROFESSIONISTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata acconto previdenziale 2024 da parte dei professionisti iscritti alla gestione separata INPS</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064368,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al III trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064372,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI E IRAP: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della II o unica rata d’acconto per l’anno 2024, di IRPEF, IVIE, IVAFE, IRES, IRAP, cedolare secca, imposta sostitutiva minimi/forfettari</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064366,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-12-02T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (novembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064504,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamento delle ritenute operate nel periodo Giugno - Novembre complessivamente inferiori a € 500.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5136788,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TFR - IMPOSTA SOSTITUTIVA SULLA RIVALUTAZIONE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’acconto sulle rivalutazioni del TFR maturate nell’anno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064566,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi mese di novembre 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al II trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064585,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di novembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064496,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (novembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064543,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di novembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064571,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento a saldo anno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064505,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064381,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-12-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RAVVEDIMENTO SPECIALE</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 8° rata per la regolarizzazione delle violazioni riferite alle dichiarazioni relative al periodo d’imposta in corso al 31.12.2021 e periodi d’imposta precedenti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064427,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">SANATORIA RATE ISTITUTI DEFINITORI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 8° rata per la regolarizzazione dell’omesso / insufficiente versamento delle somme dovute per gli istituti definitori (comma 219, Legge n. 197/2022)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064428,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute operate da giugno a novembre 2024 da parte del condominio nel caso non sia superato il limite di euro 500 di ritenute operate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064506,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DEFINIZIONE LITI FISCALI PENDENTI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento 7° rata degli importi dovuti </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064426,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-12-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento acconto 2024 da parte dei contribuenti mensili, trimestrali e trimestrali speciali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064568,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (novembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064569,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-12-27T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di novembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085617,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FATTURE ELETTRONICHE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;\">termine ultimo per la conservazione digitale delle fatture elettroniche 2023.</span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099963,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVIDENZA INTEGRATIVA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">comunicazione al fondo di previdenza integrativa/compagnia di assicurazione dell’ammontare dei contributi versati e non dedotti nel Mod. REDDITI o 730/2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085614,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">REGISTRI CONTABILI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">stampa o conservazione digitale del libro giornale, mastro, inventari, registri IVA e registro dei beni ammortizzabili relativi al 2023; in alternativa, è possibile stampare i registri solo a seguito della richiesta avanzata in sede di controllo (art. 7, comma 4-quater, D.L. n. 357/1994). <span style=\"font-size: 1rem;\"> </span></p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099959,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONSERVAZIONE DIGITALE DICHIARAZIONI FISCALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;\">Termine entro cui effettuare la conservazione digitale delle dichiarazioni fiscali relative al 2023 ai sensi del D.M. 17/6/2014.</span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099961,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di novembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5085615,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5064570,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2024-12-31T00:00:00Z"
    }
  ],
  "code": "OK",
  "message": null
}