import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

// Definiamo l'animazione per l'effetto di fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const PaymentCancelled = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      sx={{
        backgroundColor: '#fef9f9',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          backgroundColor: '#ffffff',
          padding: 4,
          borderRadius: 4,
          boxShadow: 6,
          animation: `${fadeIn} 1s ease-in-out`,
          textAlign: 'center',
          maxWidth: '90%',
          maxHeight: '90%',
          boxSizing: 'border-box',
        }}
      >
        <CancelIcon 
          color="error" 
          sx={{ fontSize: 80, animation: `${fadeIn} 1s ease-in-out` }} 
        />
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 600,
            color: '#333',
            animation: `${fadeIn} 1s ease-in-out`,
            maxWidth: '100%',
            marginBottom: 2,
          }}
        >
          Pagamento Cancellato
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          component={Link} 
          to="/cli"
          sx={{ 
            animation: `${fadeIn} 1.2s ease-in-out`,
            fontWeight: 'bold',
            padding: '12px 24px',
            textTransform: 'none',
            borderRadius: 2,
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
              backgroundColor: '#1976d2',
              transform: 'scale(1.05)',
            },
          }}
        >
          Torna alla tua area personale
        </Button>
      </Stack>
    </Box>
  );
};

export default PaymentCancelled;
