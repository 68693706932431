import "./Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer" id='footer-item'>
      <p>
        &copy; 2024 Studio Romeo. Tutti i diritti riservati. 
        <Link to="/privacy" style={{ color: '#ecf0f1', marginLeft: '10px' }}>Privacy</Link>
      </p>
    </footer>
  );
};

export default Footer;
