import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  MenuItem,
  Select,
  Stack,
  CircularProgress
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { FiSettings, FiFileText, FiDollarSign, FiLayers } from 'react-icons/fi';
import axios from "axios";
import utils from "../../utils";

const OpDomande = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [category, setCategory] = useState('Contrattualistica');
  const [faqs, setFaqs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [faqToDeleteIndex, setFaqToDeleteIndex] = useState(-1);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [faqCountsPerCategory, setFaqCountsPerCategory] = useState({
    Contrattualistica: 0,
    Amministrazione: 0,
    Paghe: 0,
    Enti: 0,
    Varie: 0
  });

  useEffect(() => {
    loadFaqs();
  }, []);

  const loadFaqs = async () => {
    try {
      const res = await axios.get(`/api/faqs`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, faqs } = res.data;
      if (success) {
        const storedFaqs = faqs;
        setFaqs(storedFaqs);
        // Calcola il numero di FAQ per categoria
        const counts = storedFaqs.reduce((acc, curr) => {
          return { ...acc, [curr.category]: acc[curr.category] + 1 };
        }, { Contrattualistica: 0, Amministrazione: 0, Paghe: 0, Enti: 0, Varie: 0 });
        setFaqCountsPerCategory(counts);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`/api/createfaq`,
        { category, question, answer },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        if (openDialog) {
          await axios.post(`/api/deletefaq`,
            { id: faqToDeleteIndex },
            {
              headers: utils.getAuthHeaders(),
            }
          );
        }
        setOpenDialog(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleEdit = (id) => {
    const index = faqs.findIndex(obj => obj.id === id);
    if (index < 0) {
      return;
    }
    setQuestion(faqs[index].question);
    setAnswer(faqs[index].answer);
    setCategory(faqs[index].category);
    setFaqToDeleteIndex(id);
    setOpenDialog(true);
  };

  const handleDelete = (index) => {
    setFaqToDeleteIndex(index);
    setDeleteConfirmationDialogOpen(true);
  };

  const confirmDeleteFaq = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/deletefaq`,
        { id: faqToDeleteIndex },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        const updatedFaqs = faqs.filter((item) => item.id !== faqToDeleteIndex);
        setFaqs(updatedFaqs);
        const index = faqs.findIndex(obj => obj.id === faqToDeleteIndex);
        setFaqCountsPerCategory({
          ...faqCountsPerCategory,
          [faqs[index].category]: faqCountsPerCategory[faqs[index].category] - 1,
        });
        setDeleteConfirmationDialogOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const cancelDeleteFaq = () => {
    setFaqToDeleteIndex(-1);
    setDeleteConfirmationDialogOpen(false);
  };

  const handleCategoryToggle = (category) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(expandedCategories.filter((cat) => cat !== category));
    } else {
      setExpandedCategories([...expandedCategories, category]);
    }
  };

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box my={4} textAlign="left">
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
            Inserisci o modifica le FAQ
          </Typography>

          <form onSubmit={handleSubmit}>

            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              fullWidth
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mt: 2 }}
            >
              <MenuItem value="Contrattualistica">Contrattualistica</MenuItem>
              <MenuItem value="Amministrazione">Amministrazione</MenuItem>
              <MenuItem value="Paghe">Paghe</MenuItem>
              <MenuItem value="Enti">Enti</MenuItem>
              <MenuItem value="Varie">Varie</MenuItem>
            </Select>

            <TextField
              label="Domanda"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Risposta"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              fullWidth
              margin="normal"
              required
              multiline
              rows={8}
            />

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mr: 1, display: 'block', margin: '0 auto' }}>
              Aggiungi FAQ
            </Button>

          </form>
          <Box mt={4}>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
              FAQ Inserite
            </Typography>
            {['Contrattualistica', 'Amministrazione', 'Paghe', 'Enti', 'Varie'].map((cat, catIndex) => (
              faqCountsPerCategory[cat] > 0 && (
                <Box key={catIndex} mt={2}>
                  <Typography variant="h5" gutterBottom onClick={() => handleCategoryToggle(cat)} style={{ cursor: 'pointer' }}>
                    <IconButton>
                      {expandedCategories.includes(cat) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {cat === 'Contrattualistica' && <FiFileText />}
                    {cat === 'Amministrazione' && <FiSettings />}
                    {cat === 'Paghe' && <FiDollarSign />}
                    {cat === 'Enti' && <FiLayers />}
                    {cat === 'Varie' && <FiLayers />}
                    {cat}
                  </Typography>

                  {expandedCategories.includes(cat) && (
                    <List>
                      {faqs.filter((item) => item.category === cat).map((item, index) => (
                        <React.Fragment key={index}>
                          <ListItem sx={{ mb: 2 }}>
                            <ListItemText
                              primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{`${index + 1}. ${item.question}`}</Typography>}
                              secondary={<Typography variant="body2" sx={{ fontStyle: 'italic' }}>{item.answer}</Typography>}
                            />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => handleEdit(item.id)}><EditIcon /></IconButton>
                              <IconButton edge="end" onClick={() => handleDelete(item.id)}><DeleteIcon /></IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          {index !== faqs.filter((item) => item.category === cat).length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  )}
                  {catIndex !== ['Contrattualistica', 'Amministrazione', 'Paghe', 'Varie'].length - 1 && <Divider />}
                </Box>
              )
            ))}
          </Box>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>{faqToDeleteIndex >= 0 ? "Modifica FAQ" : "Aggiungi FAQ"}</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Domanda"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Risposta"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                  multiline
                  rows={4}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit} color="primary">Salva</Button>
              <Button onClick={() => setOpenDialog(false)}>Annulla</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteConfirmationDialogOpen}
            onClose={cancelDeleteFaq}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Conferma Eliminazione</DialogTitle>
            <DialogContent>
              <Typography variant="          body1">
                Sei sicuro di voler eliminare questa FAQ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeleteFaq}>Annulla</Button>
              <Button onClick={confirmDeleteFaq} color="primary" autoFocus>
                Elimina
              </Button>
            </DialogActions>
          </Dialog>
        </Box>)}
    </Container>
  );
};

export default OpDomande;

