import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Divider, Typography, TextField, Button, Paper, Grid, Checkbox, FormControlLabel, IconButton, CircularProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import utils from "../../utils";

const OpEntiEdilConnectDet = () => {
  const [searchParams] = useSearchParams();
  const [statusMessageTicket, setStatusMessageTicket] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [codiceUnivoco, setCodiceUnivoco] = useState('');
  const [prezzo, setPrezzo] = useState(null);
  const [formData, setFormData] = useState({
    clientType: "",
    companyName: "",
    companyTaxCode: "",
    vatNumber: "",
    foreignCompany: false,
    companyAddress: "",
    province: "",
    city: "",
    cap: "",
    phone: "",
    email: "",
    pec: "",
    note: "",
    totalWorkAmount: "",
    startDate: "",
    endDate: "",
    activityPerformed: "",
    workDescription: "",
    edileAmount: "",

    freelanceWorker: false,
    ceSiteCode: "",
  });

  const [subContractors, setSubContractors] = useState([]);
  const emptySubData = {
    companyName: "",
    companyTaxCode: "",
    vatNumber: "",
    foreignCompany: false,
    companyAddress: "",
    province: "",
    city: "",
    cap: "",
    phone: "",
    email: "",
    pec: "",
    workDescription: "",
    startDate: null,
    endDate: null,
    activityPerformed: "",
    edileAmount: "",
    freelanceWorker: false,
    ceSiteCode: "",
  };

  const id = searchParams.get("id");

  useEffect(() => {
    loadData();
    loadTicket();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && me.op) {
          setIsLoading(false);
          return;
        } else if (error) {
          setError(true);
          setStatusMessageTicket(error);
        }
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket(error);
    }
    setIsLoading(false);
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadTicket = async () => {
    try {
      const res = await axios.get(`/api/getedilconnect?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setFormData(res.data.ticket.payload.formData);
        setSubContractors(res.data.ticket.payload.subContractors);
        setCodiceUnivoco(res.data.ticket.codiceUnivoco);
        setPrezzo(res.data.ticket.prezzo && (Number(res.data.ticket.prezzo) / 100).toFixed(2));
      } else {
        setError(true);
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubContractorInputChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    const tmpSubContractos = [...subContractors];
    tmpSubContractos[index][name] = newValue;
    setSubContractors(tmpSubContractos);
  };

  const handleAddSubContractor = () => {
    setSubContractors([...subContractors, { ...emptySubData }]);
  };

  const handleRemoveSubContractor = (index) => {
    const updatedSubContractors = [...subContractors];
    updatedSubContractors.splice(index, 1);
    setSubContractors(updatedSubContractors);
  };

  const handleSubmit = async () => {
    setStatusMessageTicket("");
    let prezzoInt = prezzo;

    if (prezzoInt) {
      prezzoInt = Math.round(Number(prezzo.toString().replace(',', '.')) * 100);
      if (!prezzoInt) {
        setError(true);
        setStatusMessageTicket("Valore non valido per il prezzo: " + prezzo);
        return;
      }
    }
    setIsLoading(true);
    try {
      const res = await axios.post(`/api/aggiornaedilconnect`,
        { formData, subContractors, codiceUnivoco, prezzo: prezzoInt, id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        setError(false);
        setStatusMessageTicket("Pratica aggiornata con successo");
      } else {
        setError(true);
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  }

  const theme = createTheme({
    typography: {
      fontSize: 10,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "auto", marginTop: "20px" }}>
        <Grid item xs={12} md={9} xl={6}>
          <Paper elevation={4} style={{ padding: 20 }}>
            <Typography variant="h4" gutterBottom textAlign="center" style={{ margin: "2px" }}>
              Dettagli Richiesta Edil Connect
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Inserisci Codice Univoco Per Cliente al Completamento
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="codiceUnivoco"
                  label="Codice Univoco"
                  variant="outlined"
                  value={codiceUnivoco}
                  onChange={(e) => setCodiceUnivoco(e.target.value)}
                  fullWidth
                />
              </Grid>
              {prezzo !== null &&
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="Prezzo"
                    value={prezzo}
                    onChange={(e) => setPrezzo(e.target.value)}
                    fullWidth
                  />
                </Grid>}
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Dati Aziendali
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <FormControl fullWidth variant="outlined" style={{ marginBottom: 5 }}>
                  <InputLabel id="client-type-label">Tipologia Committente</InputLabel>
                  <Select
                    labelId="client-type-label"
                    id="client-type"
                    name="clientType"
                    value={formData.clientType}
                    onChange={handleInputChange}
                    label="Tipologia Committente"
                  >
                    <MenuItem value="Privato Cittadino">Privato Cittadino</MenuItem>
                    <MenuItem value="Ente Pubblico/Stazione Appaltante">Ente Pubblico/Stazione Appaltante</MenuItem>
                    <MenuItem value="Azienda Privata (Giuridica)">Azienda Privata (Giuridica)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="companyName"
                  label="Ragione Sociale"
                  variant="outlined"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="companyTaxCode"
                  label="Codice Fiscale"
                  variant="outlined"
                  value={formData.companyTaxCode}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="vatNumber"
                  label="Partita Iva"
                  variant="outlined"
                  value={formData.vatNumber}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <FormControlLabel
                  control={<Checkbox name="foreignCompany" checked={formData.foreignCompany} onChange={handleInputChange} />}
                  label="Impresa Estera"
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Sede e Contatti
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="companyAddress"
                  label="Indirizzo e Sede Impresa"
                  variant="outlined"
                  value={formData.companyAddress}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="province"
                  label="Provincia"
                  variant="outlined"
                  value={formData.province}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="city"
                  label="Comune"
                  variant="outlined"
                  value={formData.city}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="cap"
                  label="Cap"
                  variant="outlined"
                  value={formData.cap}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="phone"
                  label="Telefono"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="email"
                  label="Mail"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="pec"
                  label="PEC"
                  variant="outlined"
                  value={formData.pec}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                  Dati del Cantiere
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  label="Data Inizio Lavori"
                  name="startDate"
                  variant="outlined"
                  value={formData.startDate}
                  fullWidth
                  onChange={handleInputChange}
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  label="Data Fine Lavori"
                  name="endDate"
                  variant="outlined"
                  value={formData.endDate}
                  fullWidth
                  onChange={handleInputChange}
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="totalWorkAmount"
                  label="Importo Complessivo Lavori"
                  variant="outlined"
                  value={formData.totalWorkAmount}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="workDescription"
                  label="Descrizione Lavori da Denunciare"
                  variant="outlined"
                  value={formData.workDescription}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="activityPerformed"
                  label="Attività Svolta"
                  variant="outlined"
                  value={formData.activityPerformed}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                <TextField
                  name="note"
                  label="Note"
                  variant="outlined"
                  value={formData.note}
                  onChange={handleInputChange}
                  fullWidth
                  style={{ marginBottom: 5 }}
                />
              </Grid>

              {subContractors.length > 0 && (
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                    Subappaltatrici
                  </Typography>

                  {subContractors.map((subContractor, index) => (
                    <div key={index} style={{ marginBottom: 5 }}>

                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Azienda Sub
                        </Typography>

                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="companyName"
                              label="Ragione Sociale"
                              variant="outlined"
                              value={subContractor.companyName}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="companyTaxCode"
                              label="Codice Fiscale"
                              variant="outlined"
                              value={subContractor.companyTaxCode}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="vatNumber"
                              label="Partita Iva"
                              variant="outlined"
                              value={subContractor.vatNumber}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <FormControlLabel
                              control={<Checkbox name="foreignCompany" checked={subContractor.foreignCompany} onChange={(e) => handleSubContractorInputChange(index, e)} />}
                              label="Impresa Estera"
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                              Sede e Contatti
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="companyAddress"
                              label="Indirizzo e Sede Impresa"
                              variant="outlined"
                              value={subContractor.companyAddress}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="province"
                              label="Provincia"
                              variant="outlined"
                              value={subContractor.province}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="city"
                              label="Comune"
                              variant="outlined"
                              value={subContractor.city}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="cap"
                              label="Cap"
                              variant="outlined"
                              value={subContractor.cap}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="phone"
                              label="Telefono"
                              variant="outlined"
                              value={subContractor.phone}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="email"
                              label="Mail"
                              variant="outlined"
                              value={subContractor.email}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="pec"
                              label="PEC"
                              variant="outlined"
                              value={subContractor.pec}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                              Dati del Cantiere
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              label="Data Inizio Presunta"
                              name="startDate"
                              variant="outlined"
                              value={subContractor.startDate}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              label="Data Fine Presunta"
                              name="endDate"
                              variant="outlined"
                              value={subContractor.endDate}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="workDescription"
                              label="Descrizione Lavori Subappalto"
                              variant="outlined"
                              value={subContractor.workDescription}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="edileAmount"
                              label="Importo Edile"
                              variant="outlined"
                              value={subContractor.edileAmount}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <TextField
                              name="ceSiteCode"
                              label="Codice Cantiere CE"
                              variant="outlined"
                              value={subContractor.ceSiteCode}
                              onChange={(e) => handleSubContractorInputChange(index, e)}
                              fullWidth
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                            <FormControlLabel
                              control={<Checkbox name="freelanceWorker" checked={subContractor.freelanceWorker} onChange={(e) => handleSubContractorInputChange(index, e)} />}
                              label="Lavoratore Autonomo"
                              style={{ marginBottom: 1 }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>

          </Paper>
        </Grid>

        <Grid container justifyContent="center" marginTop={2}>
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection="column"
            px={2}
          >
            {statusMessageTicket && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  flexGrow: 1,
                  backgroundColor: isError ? "#e57373" : "#81c784",
                  borderColor: isError ? "#d32f2f" : "#388e3c",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessageTicket}
              </Paper>
            )}
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              px={2}
            >
              {isLoading ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 2, px: 8 }}
                >
                  Salva
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default OpEntiEdilConnectDet;
