import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameMonth, isSameDay } from 'date-fns';
import { it } from 'date-fns/locale';
import {
  Grid, Button, Typography, Box, Paper, IconButton, Divider, Dialog, DialogTitle, DialogContent,
  CircularProgress, FormControl, Tooltip, TextField, Autocomplete,
  Stack, DialogActions, DialogContentText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";
import utils from "../../utils";

const PrenotaSala = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookings, setBookings] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setError] = useState(true);
  const [aziende, setAziende] = useState([]);
  const [azienda, setAzienda] = useState(null);

  useEffect(() => {
    loadAziendeEOp();
  }, []);

  useEffect(() => {
    const startDate = startOfWeek(startOfMonth(selectedDate));
    startDate.setHours(12);
    const endDate = endOfWeek(endOfMonth(selectedDate));
    endDate.setHours(12);
    loadPrenotazioni(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));
  }, [selectedDate]);

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadPrenotazioni = async (da, a) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/prenotazioni?da=${da}&a=${a}&r=salariunioni`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setBookings(res.data.calendario);
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handlePrevMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  const openConfirmDialog = (day) => {
    setSelectedDay(day);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedDay(null);
    setSelectedSlot(null);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogOpen(false);
    setSelectedDay(null);
    setSelectedSlot(null);
  };

  const confirmBooking = () => {
    if (selectedSlot) {
      if (isSlotBooked(selectedDay, selectedSlot)) {
        handleSlotRemove(selectedDay, selectedSlot);
      } else {
        handleSlotBooking(selectedDay, selectedSlot);
      }
    }
    closeConfirmDialog();
    closeRemoveDialog();
  };

  const handleSlotBooking = async (day, timeSlot) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/creaprenotazioneop`,
        { data: day, ora: timeSlot, risorsa: 'salariunioni', user: azienda },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        const startDate = startOfWeek(startOfMonth(selectedDate));
        startDate.setHours(12);
        const endDate = endOfWeek(endOfMonth(selectedDate));
        endDate.setHours(12);
        await loadPrenotazioni(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));
      } else {
        setError(true);
        setStatusMessage(error);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
      setLoading(false);
    }
  };

  const handleSlotRemove = async (day, timeSlot) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/rimuoviprenotazioneop`,
        { data: day, ora: timeSlot, risorsa: 'salariunioni' },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        const startDate = startOfWeek(startOfMonth(selectedDate));
        startDate.setHours(12);
        const endDate = endOfWeek(endOfMonth(selectedDate));
        endDate.setHours(12);
        await loadPrenotazioni(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const isSlotBooked = (day, timeSlot) => {
    return bookings[day][timeSlot.substring(0, 5)];
  };

  const renderSlots = day => {
    const slots = [];
    for (let hour = 9; hour < 18; hour++) {
      const hourString = hour.toString().padStart(2, '0');
      const hourToString = (hour + 1).toString().padStart(2, '0');
      const timeSlot = `${hourString}:00`;
      const booked = isSlotBooked(day, timeSlot);
      const isSelected = selectedSlot === timeSlot;
      slots.push(
        <Tooltip key={timeSlot} title={booked ? bookings[day][timeSlot.substring(0, 5)] : ''} arrow>
          <span>
            <Button
              key={timeSlot}
              variant={booked || isSelected ? "contained" : "outlined"}
              color="primary"
              onClick={() => {
                setSelectedSlot(timeSlot)
                if (booked) {
                  setConfirmDialogOpen(false);
                  setRemoveDialogOpen(true);
                }
              }}
              style={{
                margin: '4px',
                minWidth: '120px',
                textTransform: 'none',
                backgroundColor: booked || isSelected ? '#FEB200' : 'transparent',
                color: booked ? '#fff' : 'primary',
              }}
            >
              {timeSlot} - {hourToString}:00
            </Button>
          </span>
        </Tooltip>
      );
    }
    return slots;
  };

  const generateCalendar = () => {
    const startDate = startOfWeek(startOfMonth(selectedDate), { weekStartsOn: 1 });
    const endDate = endOfWeek(endOfMonth(selectedDate), { weekStartsOn: 1 });
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd EEE', { locale: it });
        const dayStr = format(day, 'yyyy-MM-dd');
        const dayBookings = bookings[dayStr];
        days.push(
          <Grid item xs={12} sm key={day} onClick={() => openConfirmDialog(dayStr)}>
            <Paper
              elevation={3}
              style={{
                padding: '10px',
                minHeight: '80px',
                borderRadius: '8px',
                border: isSameDay(day, new Date()) ? '2px solid #FEB200' : '1px solid #ccc',
                backgroundColor: isSameMonth(day, selectedDate) ? '#fff' : '#f0f0f0',
                marginBottom: '8px', // Aggiunto margine inferiore per spazio tra i giorni
              }}
            >
              <Typography variant="body2">{formattedDate}</Typography>
              {dayBookings && (
                <Box display="flex" flexWrap="wrap" justifyContent="center" mt={1}>
                  {Object.keys(dayBookings).filter(slot => dayBookings[slot]).map(slot => (
                    <Box
                      key={slot}
                      bgcolor="#FEB200"
                      color="#000"
                      px={0.5}
                      py={0.25}
                      mx={0.25}
                      mb={0.5}
                      borderRadius={1}
                      fontSize="0.6rem"
                      lineHeight={1}
                    >
                      {slot.slice(0, 5)}
                    </Box>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
        );
        day.setDate(day.getDate() + 1);
      }
      rows.push(
        <Grid container spacing={2} key={day}>
          {days}
        </Grid>
      );
      days = [];
    }
    return rows;
  };

  return (
    <Box p={3} textAlign="center">
      <Typography variant="h4" gutterBottom>Prenota la Sala Riunioni</Typography>
      <Divider style={{ marginBottom: '20px' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">{format(selectedDate, 'MMMM yyyy', { locale: it })}</Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>

      {loading ? (<Stack sx={{ my: 3 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>) : (<div />)}
      {statusMessage && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            my: 1,
            mx: 2,
            flexGrow: 1,
            backgroundColor: isError ? "#e57373" : "#81c784",
            borderColor: isError ? "#d32f2f" : "#388e3c",
            color: "black",
          }}
          role="alert"
        >
          {statusMessage}
        </Paper>
      )}

      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        {generateCalendar()}
      </div>

      {/* Dialogo di conferma per la prenotazione */}
      <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Conferma Prenotazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleziona l'azienda e un orario per il giorno {selectedDay && format(new Date(`${selectedDay} 12:00:00`), 'dd MMMM yyyy', { locale: it })}.
          </DialogContentText>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              id="azienda"
              value={azienda}
              onChange={(event, newValue) => {
                setAzienda(newValue)
              }}
              options={aziende.map((az) => az.id)}
              getOptionLabel={(id) => {
                const a = aziende.filter(az => az.id == id)[0];
                return `${a.nome} (${a.id})`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Azienda"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {selectedDay && renderSlots(selectedDay)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmBooking} color="primary" variant="contained">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={removeDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Rimuovi Prenotazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vuoi cancellare la prenotazione per il giorno {selectedDay && format(new Date(`${selectedDay} 12:00:00`), 'dd MMMM yyyy', { locale: it })} alle {selectedSlot}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmBooking} color="error" variant="contained">
            Cancella
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PrenotaSala;
